import React, { useState, useEffect } from "react";
import { Table, Input, Button, Divider, Typography } from "antd";

//Iconos y constantes
import { SelectOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateStateAction } from "../../actions/stateActions";

//Estilos
import "./AddStates.scss";

export default function AddStates(props) {
  const { curBroker, closeState, setStateId, varEffec, dataStates } = props;
  const [columnsTabla, setColumnsTabla] = useState([]);
  const [tituloModal, setTituloModal] = useState("");

  const [data, setData] = useState(dataStates);
  const [dataHs, setDataHs] = useState(dataStates);
  const [searchState, setSearchState] = useState("");
  const { Title } = Typography;
  const { Search } = Input;

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateState = (state) => dispatch(updateStateAction(state));

  var titulo = "DEPTOS - ESTADOS - PROVINCIAS";

  useEffect(() => {
    setTituloModal(titulo);
    setColumnsTabla(columnsStates);
    setData(dataStates);
    setDataHs(dataStates);
  }, [varEffec]);

  useEffect(() => {
    if (searchState) {
      const filteredState = dataStates.filter((estado) => {
        let estadoLowercase = estado.label.toLowerCase();
        let searchLowercase = searchState.toLowerCase();
        return estadoLowercase.indexOf(searchLowercase) > -1;
      });
      setData(filteredState);
    } else {
      setData(dataHs);
    }
  }, [searchState]);

  // Encabezado de Estados
  const columnsStates = [
    {
      title: "PAISES",
      dataIndex: "sta_pais",
      key: "sta_pais",
      width: "25%",
    },
    {
      title: "ESTADOS",
      dataIndex: "sta_nombre",
      key: "sta_nombre",
      width: "35%",
    },
    {
      title: "...",
      key: "action",
      width: "8%",
      align: "center",
      render: (text, record) => (
        <Button
          type="primary"
          shape="round"
          icon={<SelectOutlined />}
          onClick={() => SelectState(record)}
        ></Button>
      ),
    },
  ];

  const SelectState = (record) => {
    updateState(record);
    closeState();
  };

  const ChangeSrcState = (e) => {
    setSearchState(e.target.value);
  };

  return (
    <>
      <Title
        level={3}
        style={{
          padding: 2,
          margin: 2,
          fontWeight: 600,
          textAlign: "center",
          color: "#6A3E98",
        }}
      >
        {tituloModal}
      </Title>

      <Divider style={{ marginTop: 3, paddingTop: 3, marginBottom: 5 }} />
      <Search
        placeholder="Buscar Depto Estado Provincia"
        onChange={ChangeSrcState}
        enterButton
        allowClear
      />
      <Table
        columns={columnsTabla}
        dataSource={data}
        pagination={{ pageSize: 15 }}
        style={{ padding: 3, marginTop: 5 }}
      />
    </>
  );
}
