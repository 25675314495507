import React, { useState } from "react";
import { Modal, Button, Table, Upload, Typography } from "antd";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";

import { useDispatch } from "react-redux";
import { updateDataBrokerAction } from "../../actions/brokerActions";

//Styles
import "./UploadOffers.scss";

import Icon from "@mdi/react";
import {
  mdiFloorPlan,
  mdiMapOutline,
  mdiCashMultiple,
  mdiToilet,
  mdiCar,
  mdiBed,
} from "@mdi/js";

// Excel Render
import { ExcelRenderer } from "react-excel-renderer";

// componentes
import SRV from "../../utils/Service";
import { reloadData } from "../../utils/ReloadInfo";
import { showNotification } from "../../utils/showNotification";

export default function UploadOffers(props) {
  const { curBroker, closeModal, setIsLoading } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [brkId, setBrkId] = useState(curBroker.id);
  const [data, setData] = useState("");
  const [listaTxt, setListaTxt] = useState("");
  const [fileList, setFileList] = useState([]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateData = (state) => dispatch(updateDataBrokerAction(state));

  const { Title } = Typography;

  const propis = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (fileList.length > 0) {
        setFileList([file]);
      } else {
        setFileList([...fileList, file]);
      }
      UploadExcel(file);
      return false;
    },
    fileList,
  };

  const UploadExcel = async (file) => {
    //Obtengo la informacion del Input File
    let fileObj = file;

    //Render del Excel
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        // Elimino el encabezado del archivo
        var excel = resp.rows.filter(function (item, index, arr) {
          return item[0] !== "CIUDAD";
        });
        const bh_excel = [];
        excel.forEach(function (item, index, arr) {
          let key = index;
          let ofr_ciudad = item[0];
          let ofr_comuna = item[1];
          let zna_id = item[2];
          let zna_nombre = item[3];
          let ofr_address = item[4];
          let tin_id = item[5];
          let tin_nombre = item[6];
          let mtv_nombre = item[7];
          let ofr_area = item[8];
          let ofr_lote = item[9];
          let ofr_precio = item[10];
          let ofr_alcobas = item[11];
          let ofr_banos = item[12];
          let ofr_parking = item[13];
          let ofr_nota = item[14];

          let exceldata = {
            key: key,
            ofr_ciudad: ofr_ciudad,
            ofr_comuna: ofr_comuna,
            zna_id: zna_id,
            zna_nombre: zna_nombre,
            ofr_address: ofr_address,
            tin_id: tin_id,
            tin_nombre: tin_nombre,
            mtv_nombre: mtv_nombre,
            ofr_area: ofr_area,
            ofr_lote: ofr_lote,
            ofr_precio: ofr_precio,
            ofr_alcobas: ofr_alcobas,
            ofr_banos: ofr_banos,
            ofr_parking: ofr_parking,
            ofr_nota: ofr_nota,
          };

          bh_excel.push(exceldata);
        });
        setData(bh_excel);
        //updateTxt(bh_excel);
        //console.log(listaTxt);
      }
    });
  };

  const syncData = async () => {
    let reLoad = await reloadData();
    if (reLoad) {
      updateData(reLoad);
      return true;
    } else {
      return false;
    }
  };

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const uploadInvetoryWS = async () => {
    setIsLoading(true);
    const numRgs = data.length;
    for (var i = 0; i < data.length; i++) {
      let curReg = i + 1;
      var val_inventory = await SRV.uploadInventory(
        brkId,
        data[i].zna_id,
        data[i].tin_id,
        data[i].mtv_nombre,
        data[i].ofr_address,
        data[i].ofr_area,
        data[i].ofr_lote,
        data[i].ofr_alcobas,
        data[i].ofr_banos,
        data[i].ofr_parking,
        data[i].ofr_precio,
        data[i].ofr_nota,
        data[i].ofr_ciudad,
        data[i].ofr_comuna,
        data[i].zna_nombre,
        data[i].tin_nombre,
        numRgs,
        curReg
      );
    }
    let dataSync = syncData();
    setIsLoading(false);
    if (dataSync) {
      showNotification(
        "topRight",
        4,
        "CARGA FINALIZADA",
        "CARGA EXITOSA... REVISE EL REGISTRO DE ERRORES"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "CARGA FINALIZADA",
        "SINCRONICE SUS DATOS Y REVISE EL REGISTRO DE ERRORES"
      );
    }
    setData([]);
    setFileList([]);
    setIsLoading(false);
    closeModal();
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "CIUDAD", //10%
      dataIndex: "ofr_ciudad",
      key: "ofr_ciudad",
      width: "12%",
    },
    {
      title: "ZONA - COMUNA",
      dataIndex: "ofr_comuna",
      key: "ofr_comuna",
      width: "15%",
    },
    {
      title: "COD",
      dataIndex: "zna_id",
      key: "zna_id",
      width: "4%",
    },
    {
      title: "BARRIO",
      dataIndex: "zna_nombre",
      key: "zna_nombre",
      width: "20%",
    },
    {
      title: "SECTOR UNIDAD EDIFICIO",
      dataIndex: "ofr_address",
      key: "ofr_address",
      align: "left",
      width: "20%",
    },
    {
      title: "ID",
      dataIndex: "tin_id",
      key: "tin_id",
      width: "3%",
    },
    {
      title: "TIPO INM",
      dataIndex: "tin_nombre",
      key: "tin_nombre",
      width: "10%",
    },
    {
      title: "MOTIVO",
      dataIndex: "mtv_nombre",
      key: "mtv_nombre",
      align: "left",
      width: "7%",
    },
    {
      title: <Icon path={mdiCashMultiple} size={"30"} color="#6A3E98" />,
      dataIndex: "ofr_precio",
      key: "ofr_precio",
      align: "right",
      width: "5%",
    },
    {
      title: <Icon path={mdiFloorPlan} size={"30"} color="#6A3E98" />,
      dataIndex: "ofr_area",
      key: "ofr_area",
      align: "center",
      width: "5%",
    },
    {
      title: <Icon path={mdiMapOutline} size={"30"} color="#6A3E98" />,
      dataIndex: "ofr_lote",
      key: "ofr_lote",
      align: "center",
      width: "5%",
    },
    {
      title: <Icon path={mdiBed} size={"30"} color="#6A3E98" />,
      dataIndex: "ofr_alcobas",
      key: "ofr_alcobas",
      align: "center",
      width: "5%",
    },
    {
      title: <Icon path={mdiToilet} size={"30"} color="#6A3E98" />,
      dataIndex: "ofr_banos",
      key: "ofr_banos",
      align: "center",
      width: "5%",
    },
    {
      title: <Icon path={mdiCar} size={"30"} color="#6A3E98" />,
      dataIndex: "ofr_parking",
      key: "ofr_parking",
      align: "center",
      width: "5%",
    },
    {
      title: "+",
      dataIndex: "ofr_nota",
      key: "ofr_nota",
      width: "5%",
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <Button
          type="primary"
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => info(text)}
        />
      ),
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        CARGAR OFERTAS
      </Title>
      <div style={{ display: "flex", alignContent: "left", marginBottom: 15 }}>
        <Upload {...propis}>
          <Button type="primary" shape="round">
            <UploadOutlined /> SELECCIONE EL ARCHIVO A SUBIR
          </Button>
        </Upload>
      </div>

      <Table
        columns={header}
        dataSource={data}
        bordered
        size="middle"
        scroll={{ x: "calc(700px + 50%)", y: 640 }}
      />
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Button
          type="primary"
          shape="round"
          block
          style={{ display: fileList.length > 0 ? "block" : "none" }}
          onClick={uploadInvetoryWS}
          loading={loadingButton}
        >
          <UploadOutlined /> PROCESAR LA CARGA DEL INVENTARIO OFRECIDO
        </Button>
      </div>
    </div>
  );
}

//https://pastebin.com/yKpF4A2h

function info(text) {
  Modal.info({
    title: "OBSERVACIONES",
    content: (
      <div>
        <p>{text}</p>
      </div>
    ),
    onOk() {},
  });
}
