import { combineReducers } from "redux";

import brokerReducer from "./brokerReducer";
import isLoguedReducer from "./isLoguedReducer";
import setCurrencyReducer from "./setCurrencyReducer";
import stateReducer from "./stateReducer";

export default combineReducers({
  isLogued: isLoguedReducer,
  broker: brokerReducer,
  currency: setCurrencyReducer,
  estado: stateReducer,
});
