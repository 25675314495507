import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  Row,
  Avatar,
  Badge,
  Dropdown,
  List,
  Typography,
  Divider,
  Modal as ModalB,
} from "antd";
import Icon from "@mdi/react";
import {
  mdiChatOutline,
  mdiShieldAccountOutline,
  mdiPhone,
  mdiSeatReclineExtra,
  mdiAccountCircleOutline,
  mdiAt,
  mdiLockReset,
  mdiCloudSyncOutline,
  mdiLogout,
} from "@mdi/js";
import {
  ExclamationCircleOutlined,
  CloudSyncOutlined,
} from "@ant-design/icons";

//Firebase
import firebase from "../../utils/Firebase";
import "firebase/auth";

//Importaciones para el uso de Redux
import { useSelector, useDispatch } from "react-redux";
import { updateDataBrokerAction } from "../../actions/brokerActions";
import { updateBrokerLoguedAction } from "../../actions/isLoguedActions";

//Funciones propias
import { reloadData } from "../../utils/ReloadInfo";
import { showNotification } from "../../utils/showNotification";

//Imaganes y logos
import logo_title from "../../assest/img/logo-menu.png";
import bg3 from "../../assest/misc/bg-1.jpg";
import { AVATAR_DEFAULT, colorPrm, USER_INFO } from "../../constants";

// Elementos
import ChangeAvatar from "./ChangeAvatar";
import Modal from "../Modal";

//Estilos
import "./Header.scss";

export default function Header(props) {
  const { setIsLoading, curFecha } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [tipoModal, setTipoModal] = useState(1);
  const [widthModal, setWidthModal] = useState("70%");
  const [titleUser, setTitleUser] = useState();
  const [isInSesion, setIsInSesion] = useState(1);

  const history = useHistory();

  //useSelector para acceder al store
  const curBroker = useSelector((state) => state.broker.stateInfoBroker.broker);
  const [avatar, setAvatar] = useState(
    curBroker.brk_avatar ? curBroker.brk_avatar : AVATAR_DEFAULT
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateData = (state) => dispatch(updateDataBrokerAction(state));

  const { Title } = Typography;
  const { confirm } = ModalB;

  // MODALES/////////////////////
  const openModal = (title, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setIsVisibleModal(true);
    setTitleUser(title);
  };
  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const logOut = async () => {
    confirm({
      title: "CERRAR SESION",
      icon: <ExclamationCircleOutlined />,
      okText: "Cerrar BH",
      cancelText: "Cancelar",
      content: "Desea salir de BrokerHood?",
      onOk() {
        firebase.auth().signOut();
        localStorage.setItem(USER_INFO, 0);
        history.push("/");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const syncData = async () => {
    setIsLoading(true);
    let reLoad = await reloadData();
    if (reLoad) {
      updateData(reLoad);
      showNotification(
        "topRight",
        4,
        "SINCRONIZAR",
        "DATOS SINCRONIZADOS CON EXITO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "SINCRONIZAR",
        "ERROR DE CONEXION... INTENTE MAS TARDE"
      );
    }
    setIsLoading(false);
  };

  const gestion = [
    {
      title: curBroker.brk_company,
      icon: (
        <Icon path={mdiShieldAccountOutline} size={"32px"} color={colorPrm} />
      ),
    },
    {
      title: curBroker.brk_cargo,
      icon: <Icon path={mdiSeatReclineExtra} size={"32px"} color={colorPrm} />,
    },
    {
      title: curBroker.brk_telefono,
      icon: <Icon path={mdiPhone} size={"32px"} color={colorPrm} />,
    },
  ];

  const opciones = [
    /*{
      title: "Actualizar Perfil",
      icon: (
        <Icon path={mdiAccountCircleOutline} size={"32px"} color={colorPrm} />
      ),
      onClick: () => success(),
    },
    {
      title: "Actualizar Email",
      icon: <Icon path={mdiAt} size={"32px"} color={colorPrm} />,
      onClick: () => success(),
    },
    {
      title: "Actualizar Password",
      icon: <Icon path={mdiLockReset} size={"32px"} color={colorPrm} />,
      onClick: () => success(),
    },*/
    {
      title: "Sincronizar Datos",
      icon: <Icon path={mdiCloudSyncOutline} size={"32px"} color={colorPrm} />,
      onClick: () => syncData(),
    },
    {
      title: "Cerrar Sesion",
      icon: <Icon path={mdiLogout} size={"32px"} color={colorPrm} />,
      onClick: () => logOut(),
    },
  ];

  const ShowAlerts = (
    <form
      style={{
        backgroundColor: "#ffffff",
        marginTop: "5px",
        width: "450px",
        padding: "10px",
      }}
    >
      <div>
        <div style={{ textAlign: "center", marginTop: 5 }}>
          <Avatar
            size={150}
            src={avatar}
            style={{ marginBottom: 5 }}
            onClick={() =>
              openModal(curBroker.brk_mail.substr(0, 30), 1, "20%")
            }
          />
          <Title level={3} style={{ padding: 2, margin: 2 }}>
            {curBroker.brk_mail.substr(0, 30)}
          </Title>
        </div>
        <Divider style={{ marginTop: 3, paddingTop: 3, padding: 3 }} />
        <List
          style={{ marginTop: -20 }}
          itemLayout="horizontal"
          dataSource={gestion}
          renderItem={(item) => (
            <List.Item style={{ marginBottom: 5, marginTop: 5, padding: 3 }}>
              <List.Item.Meta
                avatar={item.icon}
                title={
                  <Title level={4} style={{ padding: 2, margin: 2 }}>
                    {item.title}
                  </Title>
                }
              />
            </List.Item>
          )}
        />
        <Divider
          style={{
            marginTop: 2,
            marginBottom: 2,
            padding: 0,
          }}
        />
        <Divider
          style={{
            marginTop: 15,
            marginBottom: 2,
            padding: 0,
          }}
        />
        <List
          style={{ marginTop: 5 }}
          itemLayout="horizontal"
          dataSource={opciones}
          renderItem={(item) => (
            <List.Item style={{ marginBottom: 5, marginTop: 5, padding: 3 }}>
              <List.Item.Meta
                avatar={item.icon}
                title={
                  <Title
                    level={4}
                    style={{ padding: 2, margin: 2, fontWeight: 300 }}
                  >
                    {item.title}
                  </Title>
                }
                onClick={item.onClick}
              />
            </List.Item>
          )}
        />
      </div>
    </form>
  );

  return (
    <>
      {isInSesion === 0 ? (
        <Redirect to="/" />
      ) : (
        <>
          <Row className="main-header">
            <span style={{ left: "10px", position: "absolute" }}>
              <img src={logo_title} className="img-logo-title" />
            </span>
            <span style={{ textAlign: "center", paddingTop: 15 }}>
              <Title level={3} style={{ color: colorPrm }}>
                {curBroker.brk_name}
              </Title>
            </span>
            <span
              className="li-options"
              style={{
                textAlign: "right",
                paddingTop: "15px",
                right: "10px",
                position: "absolute",
                width: "300px",
              }}
            >
              <ul>
                <li className="li_options">
                  <Badge
                    count={
                      curBroker.brk_messages <= 9
                        ? curBroker.brk_messages
                        : "9+"
                    }
                    offset={[-4, 8]}
                    style={{ backgroundColor: "#52c41a" }}
                  >
                    <Avatar
                      style={{ backgroundColor: "transparent" }}
                      icon={
                        <Icon
                          path={mdiChatOutline}
                          size={"32px"}
                          color="#4885ed"
                        />
                      }
                    />
                  </Badge>
                </li>
                <li className="li_options">
                  <Dropdown
                    overlay={ShowAlerts}
                    trigger={["click"]}
                    placement="bottomLeft"
                  >
                    <Avatar
                      size={50}
                      src={curBroker.brk_avatar}
                      style={{ marginTop: -8, right: -5 }}
                    />
                  </Dropdown>
                </li>
              </ul>
            </span>
          </Row>

          <Modal
            isVisibleModal={isVisibleModal}
            closeModal={closeModal}
            widthModal={widthModal}
          >
            {tipoModal === 1 ? (
              <ChangeAvatar
                uid={curBroker.id_mobile}
                closeModal={closeModal}
                setAvatar={setAvatar}
              />
            ) : (
              <></>
            )}
          </Modal>
        </>
      )}
    </>
  );
}
