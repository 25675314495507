import React, { useState } from "react";
import { Row, Form, Input, Button, Col } from "antd";
import { useHistory } from "react-router-dom";
import {
  AccountCircleOutlined,
  BusinessCenterOutlined,
  AirlineSeatReclineNormalOutlined,
  AlternateEmailOutlined,
  PhoneOutlined,
  LockOutlined,
} from "@material-ui/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateDataBrokerAction } from "../actions/brokerActions";
import { updateBrokerLoguedAction } from "../actions/isLoguedActions";

//Firebase
import firebase from "../utils/Firebase";
import "firebase/auth";

//Funciones y componentes propios
import { validateEmail } from "../utils/validateEmail";
import { showNotification } from "../utils/showNotification";
import { reloadData } from "../utils/ReloadInfo";
import SRV from "../utils/Service";
import { USER_INFO } from "../constants";

//Imaganes y logos
import bg4 from "../assest/img/bg-4.jpg";
import bg3 from "../assest/img/bg-3.jpg";
import logo from "../assest/img/logo-4.png";
import logo_left from "../assest/img/logo-footer.png";

//Estilos
import "./home.scss";

export default function FormLogin(props) {
  const { setIsLoading } = props;
  const [isLogin, setIsLogin] = useState(false);
  const [nombre, setNombre] = useState("");
  const [company, setCompany] = useState("");
  const [cargo, setCargo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repitePassword, setRepitePassword] = useState("");
  const [myForm, setMyForm] = useState(1);
  const fecha = new Date();
  const ano = fecha.getFullYear();

  const history = useHistory();

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateData = (state) => dispatch(updateDataBrokerAction(state));
  const updateLogued = (state) => dispatch(updateBrokerLoguedAction(state));

  //Funcion para la carga de la informacion del broker
  //Se disparan los dispatcher
  const updateBroker = async () => {
    let reLoad = await reloadData();
    if (reLoad) {
      updateData(reLoad);
      setIsLoading(false);
      showNotification(
        "topRight",
        4,
        "CONTROL DE ACCESO",
        "BIENVENIDO " + reLoad.broker.brk_name
      );
      history.push("/dashboard");
    } else {
      showNotification(
        "topRight",
        2,
        "CONTROL DE ACCESO",
        "BIENVENIDO DEBES SINCRONIZAR TUS DATOS"
      );
      history.push("/dashboard");
    }
    setIsLoading(false);
  };

  const onChangeNombre = (e) => {
    setNombre(e.target.value);
  };

  const onChangeCompany = (e) => {
    setCompany(e.target.value);
  };

  const onChangeCargo = (e) => {
    setCargo(e.target.value);
  };

  const onChangeTelefono = (e) => {
    setTelefono(e.target.value);
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const onChangeRepite = (e) => {
    setRepitePassword(e.target.value);
  };

  const login = async () => {
    if (!email || !password) {
      showNotification(
        "topRight",
        2,
        "ACCESO AL SISTEMA",
        "Debe registrar el E-mail y el Password!"
      );
    } else {
      if (!validateEmail(email)) {
        showNotification(
          "topRight",
          2,
          "VALIDACION DE CORREO",
          "El Email ingresado es incorrecto!"
        );
      } else {
        setIsLoading(true);
        try {
          await firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
              localStorage.setItem(USER_INFO, firebase.auth().currentUser.uid);
              updateLogued(true);
              updateBroker();
            })
            .catch((error) => {
              localStorage.setItem(USER_INFO, 0);
              setIsLoading(false);
              showNotification("topRight", 2, "ACCESO DENEGADO", error.message);
            });
        } catch (error) {
          setIsLoading(false);
          showNotification(
            "topRight",
            2,
            "CONTROL DE ACCESO",
            "ERROR AL INTENTAR LA CONEXION... INTENTE MAS TARDE"
          );
        }
      }
    }
  };

  // RECUPERAR CONTRASEÑA
  const recoverypassword = async () => {
    if (!email) {
      showNotification(
        "topRight",
        2,
        "ACCESO AL SISTEMA",
        "Debe registrar el E-mail!"
      );
    } else {
      if (!validateEmail(email)) {
        showNotification(
          "topRight",
          2,
          "VALIDACION DE CORREO",
          "El Email ingresado es incorrecto!"
        );
      } else {
        setIsLoading(true);
        try {
          // Envio Email al Webservices
          let datos = await SRV.recoveryPassword(email);

          if (datos) {
            setIsLoading(false);

            if (datos.type == 1) {
              showNotification(
                "topRight",
                4,
                "RECUPERACION DE CONTRASEÑA",
                datos.message
              );
              setMyForm(1); // Vuelo a mostrar formulario de Logueo
            } else {
              showNotification(
                "topRight",
                2,
                "RECUPERACION DE CONTRASEÑA",
                datos.message
              );
            }
          }
        } catch (error) {
          setIsLoading(false);
          showNotification(
            "topRight",
            2,
            "CONTROL DE ACCESO",
            "ERROR AL INTENTAR LA CONEXION... INTENTE MAS TARDE"
          );
        }
      }
    }
  };

  // REGISTRO DE USUARIO
  const register = async () => {
    if (
      !nombre ||
      !company ||
      !cargo ||
      !telefono ||
      !email ||
      !password ||
      !repitePassword
    ) {
      showNotification(
        "topRight",
        2,
        "ACCESO AL SISTEMA",
        "Todos los campos son obligatorios!"
      );
    } else {
      if (!validateEmail(email)) {
        showNotification(
          "topRight",
          2,
          "VALIDACION DE CORREO",
          "El Email ingresado es incorrecto!"
        );
      } else {
        if (password !== repitePassword) {
          showNotification(
            "topRight",
            2,
            "CONTRASEÑAS DIFERENTES",
            "Las contraseñas no son iguales!"
          );
        } else {
          setIsLoading(true);
          try {
            await firebase
              .auth()
              .createUserWithEmailAndPassword(email, password)
              .then(() => {
                //Se crea el broker en la base de datos remota
                createBroker();
                setMyForm(1); // Vuelo a mostrar formulario de Logueo
              })
              .catch(() => {
                setIsLoading(false);
                showNotification(
                  "topRight",
                  2,
                  "REGISTRO FALLIDO",
                  "Error al crear el usuario"
                );
              });
          } catch (error) {
            setIsLoading(false);
            showNotification(
              "topRight",
              2,
              "CONTROL DE ACCESO",
              "ERROR AL INTENTAR LA CONEXION... INTENTE MAS TARDE"
            );
          }
        }
      }
    }
  };

  //Funcion que crea el primer miembro del grupo
  const createBroker = async () => {
    const user = await firebase.auth().currentUser.uid;

    let val_broker = await SRV.createBroker(
      user,
      nombre,
      company,
      email,
      telefono,
      cargo,
      0
    );
    if (val_broker.type > 0) {
      console.log(val_broker);
      // Actualiza Nombre BH en Firebase
      const update = {
        displayName: nombre,
      };
      await firebase
        .auth()
        .currentUser.updateProfile(update)
        .then(() => {
          console.log("logueado");
        })
        .catch(() => {
          console.log("Error al actualizar el nombre");
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      console.log(val_broker.message);
    }
  };

  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      <Row>
        <Col span={17} push={7}>
          <div
            className="side-backgr"
            style={{
              backgroundImage: `url('${bg3}')`,
              height: "100vh",
              backgroundSize: "cover",
            }}
          >
            <div className="login-head">
              <img
                src={logo}
                style={{
                  width: 180,
                  marginTop: myForm < 3 ? 120 : 50,
                }}
              />
            </div>
            <div className="form-login">
              <div className="login-form">
                {myForm === 1 ? (
                  <Form className="login-form" style={{ marginTop: 50 }}>
                    <Form.Item>
                      <Input
                        prefix={
                          <AlternateEmailOutlined
                            style={{ color: "#6A3E98" }}
                          />
                        }
                        placeholder="e-mail"
                        onChange={onChangeEmail}
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        prefix={<LockOutlined style={{ color: "#6A3E98" }} />}
                        placeholder="Password"
                        onChange={onChangePassword}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        block
                        className="login-form-button"
                        size="large"
                        shape="round"
                        htmlType="button"
                        onClick={login}
                      >
                        Iniciar Sesion
                      </Button>
                    </Form.Item>
                    <div className="login-button-form">
                      <a onClick={() => setMyForm(2)}>Olvide mi password</a>
                      <a onClick={() => setMyForm(3)}>Registrarse</a>
                    </div>
                    <div className="footer-right">
                      <img src={logo_left} />
                    </div>
                  </Form>
                ) : (
                  [
                    myForm === 2 ? (
                      <Form className="login-form" style={{ marginTop: 50 }}>
                        <Form.Item>
                          <Input
                            prefix={
                              <AlternateEmailOutlined
                                style={{ color: "#6A3E98" }}
                              />
                            }
                            placeholder="e-mail"
                            onChange={onChangeEmail}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="primary"
                            block
                            className="login-form-button"
                            size="large"
                            shape="round"
                            htmlType="button"
                            onClick={recoverypassword}
                          >
                            Enviar Correo
                          </Button>
                        </Form.Item>
                        <div className="login-button-form">
                          <a onClick={() => setMyForm(1)}>Login</a>
                          <a onClick={() => setMyForm(3)}>Registrarse</a>
                        </div>
                        <div className="footer-right">
                          <img src={logo_left} />
                        </div>
                      </Form>
                    ) : (
                      <Form className="login-form" style={{ marginTop: 10 }}>
                        <Form.Item>
                          <Input
                            prefix={
                              <AccountCircleOutlined
                                style={{ color: "#6A3E98" }}
                              />
                            }
                            placeholder="Nombre"
                            onChange={onChangeNombre}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Input
                            prefix={
                              <BusinessCenterOutlined
                                style={{ color: "#6A3E98" }}
                              />
                            }
                            placeholder="Compañia"
                            onChange={onChangeCompany}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Input
                            prefix={
                              <AirlineSeatReclineNormalOutlined
                                style={{ color: "#6A3E98" }}
                              />
                            }
                            placeholder="Cargo"
                            onChange={onChangeCargo}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Input
                            prefix={
                              <PhoneOutlined style={{ color: "#6A3E98" }} />
                            }
                            placeholder="Telefono"
                            onChange={onChangeTelefono}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Input
                            prefix={
                              <AlternateEmailOutlined
                                style={{ color: "#6A3E98" }}
                              />
                            }
                            placeholder="e-mail"
                            onChange={onChangeEmail}
                          />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                          ]}
                        >
                          <Input.Password
                            prefix={
                              <LockOutlined style={{ color: "#6A3E98" }} />
                            }
                            placeholder="Password"
                            onChange={onChangePassword}
                          />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please repita el password!",
                            },
                          ]}
                        >
                          <Input.Password
                            prefix={
                              <LockOutlined style={{ color: "#6A3E98" }} />
                            }
                            placeholder="Repetir Password"
                            onChange={onChangeRepite}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="primary"
                            block
                            className="login-form-button"
                            size="large"
                            shape="round"
                            htmlType="button"
                            onClick={register}
                          >
                            Unirse a BrokerHood
                          </Button>
                        </Form.Item>
                        <div className="login-button-form">
                          <a onClick={() => setMyForm(1)}>Login</a>
                          <a onClick={() => setMyForm(2)}>Olvide mi Password</a>
                        </div>
                        <div className="footer-right">
                          <img src={logo_left} />
                        </div>
                      </Form>
                    ),
                  ]
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col span={7} pull={17}>
          <div
            style={{
              backgroundImage: `url('${bg4}')`,
              height: "100hv",
              backgroundSize: "cover",
              padding: "2rem 1.5rem",
            }}
          >
            <div className="side-backgr-left">
              <div className="logo">
                <h3 className="wcome-title">Bienvenid@ a BrokerHood!</h3>
                <h4 className="wcome-subtitle" style={{ marginTop: 0 }}>
                  OPTIMIZA - PERSONALIZA - INCREMENTA
                </h4>
              </div>
              <div className="copy-foot">
                &copy; Powered by AAL-Team, S.A. {ano}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
