import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  Avatar,
  Typography,
  Menu,
  Dropdown,
} from "antd";
import Highlighter from "react-highlight-words";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";

//Iconos y constantes
import {
  SearchOutlined,
  EditOutlined,
  CameraOutlined,
  DeleteOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { URL_OFFERT, CURRENT_STATE } from "../../constants";

//Componentes propios de detalle y actualizacion
import ModalApp from "../Modal";
import DetalleOffer from "../DetalleOffer";
import EditDetalleOfr from "../EditDetalleOfr";
import AddFotosOffer from "../AddFotosOffer";
import UploadOffers from "../UploadOffers";
import DeleteOffer from "../DeleteOffer";
import Downloadciudad from "./Downloadciudad";
import CreateOffer from "../CreateOffer";
import CrearCiudad from "../CrearCiudad";
import AddStates from "../AddStates";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Estilos
import "./Offers.scss";

export default function Offerts(props) {
  const { setIsLoading } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [stateId, setStateId] = useState(0);
  const [detalleOffer, setDetalleOffer] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalText, setModalText] = useState("Desea eliminar la oferta?");
  const [listaCities, setListaCities] = useState([]);

  //useSelector para acceder al store
  const curBroker = useSelector((state) => state.broker.stateInfoBroker.broker);
  const data = useSelector((state) => state.broker.stateInfoBroker.ofertas);

  const dataStates = useSelector(
    (state) => state.broker.stateInfoBroker.estados
  );
  const dataCities = useSelector(
    (state) => state.broker.stateInfoBroker.ciudades
  );
  const dataComunas = useSelector(
    (state) => state.broker.stateInfoBroker.comunas
  );
  const dataZonas = useSelector((state) => state.broker.stateInfoBroker.zonas);
  const dataTiposInm = useSelector(
    (state) => state.broker.stateInfoBroker.tipos_inm
  );
  const currentState = useSelector((state) => state.estado.stateState);

  useEffect(() => {
    const newCities = dataCities.filter((ciudades) => {
      return (
        Number.parseInt(ciudades.id_parent) ===
        Number.parseInt(currentState.value)
      );
    });
    setListaCities(newCities);
  }, [currentState]);

  //const { confirm } = Modal;
  const { Title } = Typography;

  const onClickMenu = ({ key }) => {
    if (key === "1") {
      setStateId(9);
      openModal(null, 0, "700px");
    } else if (key === "2") {
      setStateId(6);
      openModal(null, 0, "40%");
    } else if (key === "3") {
      window.open("https://brokerhood.com/api/export/104");
    } else if (key === "4") {
      window.open("https://brokerhood.com/api/export/106");
    } else if (key === "5") {
      openModal(null, 4, "98%");
    } else if (key === "6") {
      window.open("https://brokerhood.com/api/export/110/" + curBroker.id);
    } else if (key === "7") {
      setStateId(8);
      openModal(null, 0, "700px");
    } else {
      window.open("https://brokerhood.com/api/export/107/" + curBroker.id);
    }
  };

  const menu = (
    <Menu onClick={onClickMenu}>
      <Menu.Item key="1">Crear Ubicaciones</Menu.Item>
      <Menu.Item key="2">Descargar Ubicaciones</Menu.Item>
      <Menu.Item key="3">Tipos de Inmueble</Menu.Item>
      <Menu.Item key="4">Formato de Oferta</Menu.Item>
      <Menu.Item key="5">Importar Ofertas</Menu.Item>
      <Menu.Item key="6">Descargar Errores</Menu.Item>
      <Menu.Item key="7">Crear Oferta Manual</Menu.Item>
      <Menu.Item key="8">Descargar Inventario</Menu.Item>
    </Menu>
  );

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleOffer(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const closeState = () => {
    setIsVisibleModal(false);
    openModal(null, stateId, "700px");
  };

  const showConfirm = (record) => {
    setDetalleOffer(record);
    setVisible(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    setModalText("Eliminando la Oferta... por favor espere");
    setTimeout(() => {
      setModalText("Desea eliminar la Oferta");
      setConfirmLoading(false);
      setVisible(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  //Funciones para busqueda en las columnas
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.focus());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  //Fin funciones para busqueda en las columnas

  //Columnas de la tabla
  const columns = [
    {
      title: "IMG",
      dataIndex: "ofr_image1",
      key: "ofr_image1",
      width: "5%",
      render: (ofr_image1) => (
        <Avatar
          size={45}
          src={URL_OFFERT + ofr_image1}
          style={{ margin: 0, padding: 0 }}
        />
      ),
    },
    {
      title: "TIPO",
      dataIndex: "tin_nombre",
      key: "tin_nombre",
      width: "10%",
      ...getColumnSearchProps("tin_nombre"),
    },
    {
      title: "UBICACION",
      dataIndex: "zna_nombre",
      key: "zna_nombre",
      ...getColumnSearchProps("zna_nombre"),
    },
    {
      title: "SECTOR",
      dataIndex: "ofr_address",
      key: "ofr_address",
      ...getColumnSearchProps("ofr_address"),
    },
    {
      title: "OFERTA PARA",
      dataIndex: "mtv_nombre",
      key: "mtv_nombre",
      ...getColumnSearchProps("mtv_nombre"),
    },
    {
      title: "PRECIO",
      dataIndex: "ofr_precio",
      key: "ofr_precio",
      width: "10%",
      align: "right",
      ...getColumnSearchProps("ofr_precio"),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => openModal(record, 1, "1300px")}
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="ghost"
          size="large"
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => openModal(record, 2, 800)}
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          size="large"
          shape="circle"
          icon={<CameraOutlined />}
          onClick={() => openModal(record, 3, "50%")}
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="danger"
          size="large"
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() => openModal(record, 5, "400px")}
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <div
        style={{
          display: "flex",
          paddingLeft: "35px",
          paddingBottom: "5px",
        }}
      >
        <Space>
          <Dropdown
            overlay={menu}
            placement="topCenter"
            style={{ fontSize: "20px" }}
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
              style={{ fontSize: "18px" }}
            >
              Gestionar Ofertas <DownOutlined />
            </a>
          </Dropdown>
        </Space>
      </div>

      <Table
        columns={columns}
        dataSource={data}
        style={{ padding: 3, marginTop: 5 }}
      />
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 1 ? (
          <DetalleOffer
            detalleOffer={detalleOffer}
            varEffec={Math.random()}
            currentState={currentState}
          />
        ) : tipoModal === 2 ? (
          <EditDetalleOfr
            detalleOffer={detalleOffer}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
          />
        ) : tipoModal === 3 ? (
          <AddFotosOffer
            detalleOffer={detalleOffer}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
          />
        ) : tipoModal === 4 ? (
          <UploadOffers
            curBroker={curBroker}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
          />
        ) : tipoModal === 5 ? (
          <DeleteOffer
            detalleOffer={detalleOffer}
            closeModal={closeModal}
            tipo={1}
          />
        ) : tipoModal === 6 ? (
          <Downloadciudad
            ciudades={listaCities}
            closeModal={closeModal}
            currentState={currentState}
          />
        ) : tipoModal === 9 ? (
          <CrearCiudad
            curBroker={curBroker}
            ciudades={listaCities}
            dataComunas={dataComunas}
            dataZonas={dataZonas}
            closeModal={closeModal}
            varEffec={Math.random()}
            currentState={currentState}
          />
        ) : tipoModal === 8 ? (
          <CreateOffer
            curBroker={curBroker}
            closeModal={closeModal}
            dataCiudades={listaCities}
            dataComunas={dataComunas}
            dataZonas={dataZonas}
            dataTiposInm={dataTiposInm}
            currentState={currentState}
          />
        ) : (
          <AddStates
            curBroker={curBroker}
            closeState={closeState}
            setStateId={setStateId}
            varEffec={Math.random()}
            dataStates={dataStates}
          />
        )}
      </ModalApp>
    </div>
  );
}
