import React, { useState } from "react";
import { Table, Input, Button, Space, Avatar, Modal as ModalB } from "antd";
import Highlighter from "react-highlight-words";

//Iconos y constantes
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { URL_OFFERT } from "../../constants";

//Componentes propios de detalle y actualizacion
import Modal from "../Modal";
import Loading from "../Loading";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Estilos
import "./BrokerCath.scss";

export default function BrokerCath() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleOffer, setDetalleOffer] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  //useSelector para acceder al store
  const curBroker = useSelector((state) => state.broker.stateInfoBroker.broker);
  const data = useSelector(
    (state) => state.broker.stateInfoBroker.broker_catch
  );

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleOffer(record);
    setIsVisibleModal(true);
  };
  const closeModal = () => {
    setIsVisibleModal(false);
  };

  function eliminar(record) {
    ModalB.confirm({
      title: "Desea Elminar el Registro?",
      icon: <ExclamationCircleOutlined />,
      okText: "Si",
      cancelText: "No",
      content: "some messages...some messages...",
    });
  }

  //Funciones para busqueda en las columnas
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.focus());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  //Fin funciones para busqueda en las columnas

  //Columnas de la tabla
  const columns = [
    {
      title: "IMG",
      dataIndex: "ofr_image1",
      key: "ofr_image1",
      width: "5%",
      render: (ofr_image1) => (
        <Avatar
          size={45}
          src={URL_OFFERT + ofr_image1}
          style={{ margin: 0, padding: 0 }}
        />
      ),
    },
    {
      title: "OFERENTE",
      dataIndex: "oferente",
      key: "oferente",
      ...getColumnSearchProps("oferente"),
    },
    {
      title: "DIRECCION",
      dataIndex: "ofr_address",
      key: "ofr_address",
      ...getColumnSearchProps("ofr_address"),
    },
    {
      title: "SOLICITANTE",
      dataIndex: "solicitante",
      key: "solicitante",
      ...getColumnSearchProps("solicitante"),
    },
    {
      title: "DIRECCION",
      dataIndex: "ord_address",
      key: "ord_address",
      ...getColumnSearchProps("ord_address"),
    },
    {
      title: "DESDE",
      dataIndex: "ord_desde",
      key: "ord_desde",
      width: "10%",
      align: "right",
      ...getColumnSearchProps("ord_desde"),
    },
    {
      title: "HASTA",
      dataIndex: "ord_hasta",
      key: "ord_hasta",
      width: "10%",
      align: "right",
      ...getColumnSearchProps("ord_hasta"),
    },
  ];

  //Renderizacion del componente
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="main-content">
          <Table
            columns={columns}
            dataSource={data}
            style={{ padding: 3, marginTop: 5 }}
          />

          <Modal
            isVisibleModal={isVisibleModal}
            closeModal={closeModal}
            widthModal={widthModal}
          >
            detalles
          </Modal>
        </div>
      )}
    </>
  );
}
