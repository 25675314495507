import React, { useState, useEffect } from "react";
import { Table, Input, Button, Space, Avatar, Modal as ModalB } from "antd";
import Highlighter from "react-highlight-words";

//Iconos y constantes
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { URL_OFFERT } from "../../constants";

//Componentes propios de detalle y actualizacion
import Modal from "../Modal";
import Loading from "../Loading";
import DeleteOffer from "../DeleteOffer";
import CreateOrder from "../CreateOrder";
import AddStates from "../AddStates";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Estilos
import "./Orders.scss";

export default function Orders() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleOffer, setDetalleOffer] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [listaCities, setListaCities] = useState([]);
  const [stateId, setStateId] = useState(0);

  //useSelector para acceder al store
  const curBroker = useSelector((state) => state.broker.stateInfoBroker.broker);
  const data = useSelector((state) => state.broker.stateInfoBroker.ordenes);
  const dataStates = useSelector(
    (state) => state.broker.stateInfoBroker.estados
  );
  const dataCities = useSelector(
    (state) => state.broker.stateInfoBroker.ciudades
  );
  const dataComunas = useSelector(
    (state) => state.broker.stateInfoBroker.comunas
  );
  const dataZonas = useSelector((state) => state.broker.stateInfoBroker.zonas);
  const dataTiposInm = useSelector(
    (state) => state.broker.stateInfoBroker.tipos_inm
  );

  const currentState = useSelector((state) => state.estado.stateState);

  useEffect(() => {
    const newCities = dataCities.filter((ciudades) => {
      return (
        Number.parseInt(ciudades.id_parent) ===
        Number.parseInt(currentState.value)
      );
    });
    setListaCities(newCities);
  }, [currentState]);

  const openModal = (record, tipo, ancho) => {
    setStateId(tipo);
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleOffer(record);
    setIsVisibleModal(true);
  };
  const closeModal = () => {
    setIsVisibleModal(false);
  };
  //////////////////////////////////////////////////////////////
  const closeState = () => {
    setIsVisibleModal(false);
    openModal(null, 1, "700px");
  };
  //Funciones para busqueda en las columnas
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.focus());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  //Fin funciones para busqueda en las columnas

  //Columnas de la tabla
  const columns = [
    {
      title: "TIPO",
      dataIndex: "tin_nombre",
      key: "tin_nombre",
      width: "10%",
      ...getColumnSearchProps("tin_nombre"),
    },
    {
      title: "AREA",
      dataIndex: "ord_area",
      key: "ord_area",
      width: "5%",
    },
    {
      title: "LOTE",
      dataIndex: "ord_lote",
      key: "ord_lote",
      width: "5%",
    },
    {
      title: "$ DESDE",
      dataIndex: "ord_desde",
      key: "ord_desde",
      width: "5%",
    },
    {
      title: "$ HASTA",
      dataIndex: "ord_hasta",
      key: "ord_hasta",
      width: "5%",
    },
    {
      title: "ZONAS",
      dataIndex: "ord_zonas",
      key: "ord_zonas",
      ...getColumnSearchProps("zna_nombre"),
    },
    {
      title: "PEDIDO PARA",
      dataIndex: "mtv_nombre",
      key: "mtv_nombre",
      ...getColumnSearchProps("mtv_nombre"),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="danger"
          size="large"
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() => openModal(record, 5, "400px")}
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="main-content">
          <div
            style={{
              display: "flex",
              paddingLeft: "35px",
              paddingBottom: "5px",
            }}
          >
            <Space>
              <Button
                type="link"
                size="large"
                style={{ fontSize: "18px" }}
                onClick={() => openModal(null, 0, "700px")}
              >
                Crear Pedido
              </Button>
            </Space>
          </div>

          <Table
            columns={columns}
            dataSource={data}
            style={{ padding: 3, marginTop: 5 }}
          />

          <Modal
            isVisibleModal={isVisibleModal}
            closeModal={closeModal}
            widthModal={widthModal}
          >
            {tipoModal === 1 ? (
              <CreateOrder
                curBroker={curBroker}
                closeModal={closeModal}
                dataCiudades={listaCities}
                dataComunas={dataComunas}
                dataZonas={dataZonas}
                dataTiposInm={dataTiposInm}
                currentState={currentState}
              />
            ) : tipoModal === 5 ? (
              <DeleteOffer
                detalleOffer={detalleOffer}
                closeModal={closeModal}
                tipo={2}
              />
            ) : (
              <AddStates
                curBroker={curBroker}
                closeState={closeState}
                varEffec={Math.random()}
                dataStates={dataStates}
              />
            )}
          </Modal>
        </div>
      )}
    </>
  );
}
