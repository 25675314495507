import React, { useState } from "react";
import { Typography, Upload, message, Button } from "antd";

//Iconos
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateDataBrokerAction } from "../../actions/brokerActions";

// Firebase
import * as firebase from "firebase";

// componentes
import { showNotification } from "../../utils/showNotification";
import SRV from "../../utils/Service";

export default function ChangeAvatar(propis) {
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [fileList, setFileList] = useState([]);
  const [imgUri, setImgUri] = useState();
  const { Title } = Typography;
  const { uid, closeModal, setAvatar } = propis;

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateData = (state) => dispatch(updateDataBrokerAction(state));

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setImageUrl();
    },
    beforeUpload: (file) => {
      // Condiciones para imagenes jpeg/png y menos de 2MB
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("Solo puedes subir archivos JPG / PNG!");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("La imagen debe tener menos de 2 MB!");
        return false;
      }

      // Almacenos el array de Archivos
      if (fileList.length > 0) {
        setFileList([file]);
      } else {
        setFileList([...fileList, file]);
      }

      // Convierto la imagen en Base64 Para el preview de upload
      getBase64(file, (imageUrl) => setImageUrl(imageUrl), setLoading(false));

      // Creo apartir de un archivo una uri para el Firebase
      var fileuri = URL.createObjectURL(file);
      setImgUri(fileuri);
      console.log(fileuri);

      return false;
    },
    fileList,
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  // Subida de Imagen a Firebase desde uri Local
  const uploadAvatar = () => {
    setLoadingButton(true);
    uploadImage(imgUri, uid).then(() => {
      //Imagen subida correctamente
      updatePhotoUrl(uid);
    });
  };

  // Subo la imagen a firebase
  const uploadImage = async (uri, nameImage) => {
    setLoading(true);
    const response = await fetch(uri);
    const blob = await response.blob();

    const ref = firebase.storage().ref().child(`avatar/${nameImage}`);
    return ref.put(blob);
  };

  // Actualizo la Imagen en Firebase
  const updatePhotoUrl = (uid) => {
    firebase
      .storage()
      .ref(`avatar/${uid}`)
      .getDownloadURL()
      .then(async (result) => {
        const update = {
          photoURL: result,
        };
        await firebase.auth().currentUser.updateProfile(update);
        updateAvatarBroker();
        console.log("foto Actualizada en firebase");
      })
      .catch(() => {
        //Error descargando la foto
        showNotification(
          2,
          "ERROR DE ACTUALIZACION",
          "Error al descargar la foto"
        );
      });
  };

  ////////////////////////////////////////
  // Actualizo la informacion del Broker
  const updateAvatarBroker = async () => {
    const user = firebase.auth().currentUser;
    let val_broker = await SRV.updateAvatar(
      firebase.auth().currentUser.uid,
      user.photoURL
    );
    if (val_broker.type > 0) {
      updateData(val_broker.datos); // Actualizo Datos de Broker
      setAvatar(user.photoURL); // Seteo Avatar
      showNotification(
        4,
        "ACTUALIZACION EXITOSA",
        "Avatar Actualizado Exitosamente."
      );
      setLoading(false); // Reinicio el Loading de Subida de Avatar
      setLoadingButton(false); // Reinicio Loading de Button de Avatar
      setImageUrl(); // Limpio Imagen Avatar cargada
      setFileList([]); // Limpio Array de Archivos
      closeModal();
    } else {
      showNotification(2, "ERROR DE ACTUALIZACION", "Error");
    }
  };
  /////////////////////////////////////////
  /////////////////////////////////////////

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Subir</div>
    </div>
  );

  return (
    <>
      <Title level={4} style={{ padding: 2, margin: 2 }}>
        Cambiar Avatar
      </Title>
      <Upload
        {...props}
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={true}
      >
        {imageUrl ? (
          <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
      <Button
        type="primary"
        shape="round"
        block
        style={{ display: fileList.length > 0 ? "block" : "none" }}
        onClick={uploadAvatar}
        loading={loadingButton}
      >
        <UploadOutlined /> CAMBIAR AVATAR
      </Button>
    </>
  );
}
