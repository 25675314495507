import React from "react";
import logo_footer from "../assest/img/logo-footer.png";

export default function Foother() {
  const fecha = new Date();
  const ano = fecha.getFullYear();
  return (
    <div>
      Powered by{" "}
      <img
        src={logo_footer}
        style={{ height: "20px", padding: "2px, 5px, 0, 2px" }}
      />{" "}
      ©{ano}
    </div>
  );
}
