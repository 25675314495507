const initialProps = {
  stateCurrency: 0,
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "UPDATE_CURRENCY":
      return {
        ...state,
        stateCurrency: action.payload,
      };
    default:
      return state;
  }
}
