import React, { useState } from "react";
import { Table, Input, Button, Space, Divider, Typography } from "antd";
import Highlighter from "react-highlight-words";
import { DownloadOutlined } from "@ant-design/icons";

//Iconos y constantes
import { SearchOutlined } from "@ant-design/icons";

export default function DownloadCiudad(props) {
  const { ciudades, closeModal, currentState } = props;
  const [searchInput, setSearchInput] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const { Title } = Typography;

  //Funciones para busqueda en las columnas
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.focus());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  //Fin funciones para busqueda en las columnas

  //Columnas de la tabla
  const columns = [
    {
      title: "Ciudad",
      dataIndex: "label",
      key: "label",
      width: "45%",
      ...getColumnSearchProps("label"),
    },
    {
      title: "Descargar",
      key: "action",
      width: "8%",
      align: "center",
      render: (text, record) => (
        <Button
          type="primary"
          shape="round"
          icon={<DownloadOutlined />}
          onClick={() =>
            window.open("https://brokerhood.com/api/export/105/" + record.value)
          }
        ></Button>
      ),
    },
  ];

  return (
    <>
      <Title
        level={4}
        style={{
          padding: 2,
          margin: 2,
          fontWeight: 600,
          textAlign: "center",
          color: "#6A3E98",
        }}
      >
        {"DESCARGAR UBICACIONES DE " +
          currentState.sta_nombre +
          " " +
          currentState.sta_pais}
      </Title>
      <Divider style={{ marginTop: 3, paddingTop: 3, marginBottom: 5 }} />
      <Table
        columns={columns}
        dataSource={ciudades}
        pagination={{ pageSize: 10 }}
        style={{ padding: 3, marginTop: 5 }}
      />
      <Divider
        style={{ marginTop: 3, paddingTop: 3, padding: 3, marginBottom: 3 }}
      />
      <div>
        <Button
          type="primary"
          shape="round"
          block
          onClick={() => window.open("https://brokerhood.com/api/export/105/")}
        >
          <DownloadOutlined /> Descargar Todas
        </Button>
      </div>
    </>
  );
}
