import React, { useState } from "react";
import { Typography, Button, Space } from "antd";

import { DeleteOutlined, UndoOutlined } from "@ant-design/icons";

//Estilos
import "./DeleteOffer.scss";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateDataBrokerAction } from "../../actions/brokerActions";

// Componentes
import SRV from "../../utils/Service";
import { showNotification } from "../../utils/showNotification";

export default function EditDetalleOfr(props) {
  const { detalleOffer, closeModal, tipo } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const { Title } = Typography;

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateData = (state) => dispatch(updateDataBrokerAction(state));

  /////////////////////////////////
  // GUARDO CAMBIOS
  const guardarCambios = async () => {
    setLoadingButton(true);

    let val_ofr = await SRV.deleteOfferOrder(
      detalleOffer.id,
      detalleOffer.brk_id,
      detalleOffer.bkh_id,
      tipo
    );

    if (val_ofr.type > 0) {
      updateData(val_ofr.datos);
      showNotification(
        "topRight",
        4,
        "PROCESO EXITOSO",
        "Datos Actualizados Exitosamente."
      );
      setLoadingButton(false);
      closeModal();
    } else {
      showNotification("topRight", 2, val_ofr.message, "Error");
    }
  };

  //Renderizacion del componente
  return (
    <div className="main-content-det">
      <form
        style={{
          backgroundColor: "#ffffff",
          marginTop: "5px",
          width: "100%",
        }}
      >
        <div style={{ width: "100%", justifyContent: "center" }}>
          <div style={{ margin: "5px", padding: "5px" }}>
            <Title
              level={4}
              style={{
                padding: 2,
                margin: 2,
                marginBottom: 15,
                fontWeight: 600,
                textAlign: "center",
                color: "#6A3E98",
              }}
            >
              {tipo === 1 ? "ELIMINAR LA OFERTA?" : "ELIMINAR EL PEDIDO"}
            </Title>
          </div>
          <div style={{ paddingLeft: "150px" }}>
            <Space>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={guardarCambios}
                loading={loadingButton}
              >
                <DeleteOutlined /> SI
              </Button>
              <Button shape="round" size="large" onClick={() => closeModal()}>
                <UndoOutlined /> NO
              </Button>
            </Space>
          </div>
        </div>
      </form>
    </div>
  );
}
