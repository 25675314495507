import React, { useState } from "react";
import { Layout } from "antd";
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

//Importo Store y Provider desde Redux
import store from "./store";
import { Provider } from "react-redux";
import { useSelector } from "react-redux";

//Paginas
import Loading from "./components/Loading";
import FormLogin from "./pages/FormLogin";
import Dashboard from "./pages/dashboard";
import Error404 from "./pages/error404";

export default function App() {
  const { Content } = Layout;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Provider store={store}>
      <Layout>
        <Router>
          <Content>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Switch>
                  <Route path="/" exact={true}>
                    <FormLogin setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/dashboard" exact={true}>
                    <Dashboard setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/error404" exact={true}>
                    <Error404 />
                  </Route>
                </Switch>
              </>
            )}
          </Content>
        </Router>
      </Layout>
    </Provider>
  );
}
