import { notification } from "antd";

export function showNotification(placement, type, message, description) {
  if (type === 1) {
    notification.info({
      message: `${message}`,
      description: `${description}`,
      placement,
    });
  } else if (type === 2) {
    notification.error({
      message: `${message}`,
      description: `${description}`,
      placement,
    });
  } else if (type === 3) {
    notification.warning({
      message: `${message}`,
      description: `${description}`,
      placement,
    });
  } else {
    notification.success({
      message: `${message}`,
      description: `${description}`,
      placement,
    });
  }
}
