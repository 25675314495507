import { showNotification } from "./showNotification";
import { URL_SERVICE } from "../constants";
const cur_vrs = "1.0.6";

class SRV {
  //Ciudades donde esta
  getCiudades() {
    return fetch(`${URL_SERVICE}/13`, {
      method: "POST",
      body: JSON.stringify({
        tipo: 1,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }
  //Creacion del broker asociado al usuario registrado
  createBroker(
    user,
    nameBroker,
    companyBroker,
    email,
    telefonoBroker,
    cargoBroker,
    ciudad
  ) {
    return fetch(`${URL_SERVICE}/101`, {
      method: "POST",
      body: JSON.stringify({
        id_mobile: user,
        brk_name: nameBroker,
        brk_mail: email,
        brk_company: companyBroker,
        brk_cargo: cargoBroker,
        ciu_id: ciudad,
        brk_telefono: telefonoBroker,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  //Creacion del broker asociado al usuario registrado
  updateAvatar(user, url) {
    return fetch(`${URL_SERVICE}/2`, {
      method: "POST",
      body: JSON.stringify({
        id_mobile: user,
        brk_avatar: url,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  getBrokerhoods(user, search) {
    return fetch(`${URL_SERVICE}/3`, {
      method: "POST",
      body: JSON.stringify({
        id_mobile: user,
        search: search,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  getBrokerhoodsLimit(user, inicio) {
    return fetch(`${URL_SERVICE}/4`, {
      method: "POST",
      body: JSON.stringify({
        id_mobile: user,
        inicio: inicio,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  //Se traen las comunas para el BH
  getComunasHood(ciu_id) {
    return fetch(`${URL_SERVICE}/13`, {
      method: "POST",
      body: JSON.stringify({
        tipo: 3,
        ciu_id: ciu_id,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  //Se traen las comunas para el BH
  getZonasHood(bkh_id) {
    return fetch(`${URL_SERVICE}/13`, {
      method: "POST",
      body: JSON.stringify({
        tipo: 4,
        bkh_id: bkh_id,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  //Creacion del brokerhood y el mienbro admin
  createBrokerhood(
    user,
    nameBhood,
    asuntoBhood,
    precioInicial,
    precioFinal,
    bkhComunas,
    imagesSelected
  ) {
    console.log("imagen");
    console.log(bkhComunas);
    let uploadData = new FormData();
    uploadData.append("submit", "ok");
    uploadData.append("brk_id", user);
    uploadData.append("app_origen", 2);
    uploadData.append("app_version", cur_vrs);
    uploadData.append("bkh_nombre", nameBhood);
    uploadData.append("bkh_asunto", asuntoBhood);
    uploadData.append("bkh_minimo", precioInicial);
    uploadData.append("bkh_maximo", precioFinal);
    uploadData.append("bkh_comunas", bkhComunas);
    uploadData.append("bkh_avatar", {
      type: "image/jpeg",
      uri: imagesSelected[0],
      name: "uploadimagetmp.jpeg",
    });
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_SERVICE}/5`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  getBroker(user) {
    return fetch(`${URL_SERVICE}/6`, {
      method: "POST",
      body: JSON.stringify({
        id_mobile: user,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  //Actualizacion datos del broker asociado al usuario registrado
  updateDatosBroker(
    user,
    tipo,
    nameBroker,
    companyBroker,
    teleBroker,
    cargoBroker
  ) {
    return fetch(`${URL_SERVICE}/7`, {
      method: "POST",
      body: JSON.stringify({
        id_mobile: user,
        tipo: tipo,
        brk_name: nameBroker,
        brk_company: companyBroker,
        brk_telefono: teleBroker,
        brk_cargo: cargoBroker,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  updateMailBroker(user, tipo, mailBroker) {
    return fetch(`${URL_SERVICE}/7`, {
      method: "POST",
      body: JSON.stringify({
        id_mobile: user,
        tipo: tipo,
        brk_mail: mailBroker,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  updateTokenBroker(user, tipo, token) {
    return fetch(`${URL_SERVICE}/7`, {
      method: "POST",
      body: JSON.stringify({
        id_mobile: user,
        tipo: tipo,
        brk_token: token,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }
  //Fin de actualizacion datos del broker

  //Traer los miembros de un Brokerhood
  getBrokerhoodMembers(idBrokerhood) {
    return fetch(`${URL_SERVICE}/8`, {
      method: "POST",
      body: JSON.stringify({
        bkh_id: idBrokerhood,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  //Traer los miembros de un Brokerhood
  getFindBrokers(idBrokerhood, nombreBroker, emailBroker, telefonoBroker) {
    return fetch(`${URL_SERVICE}/10`, {
      method: "POST",
      body: JSON.stringify({
        bkh_id: idBrokerhood,
        brk_name: nombreBroker,
        brk_mail: emailBroker,
        brk_telefono: telefonoBroker,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  //Traer los miembros de un Brokerhood
  getFindBrokers2(idBrokerhood, nombreBroker) {
    return fetch(`${URL_SERVICE}/10`, {
      method: "POST",
      body: JSON.stringify({
        bkh_id: idBrokerhood,
        brk_name: nombreBroker,
        brk_mail: "",
        brk_telefono: "",
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  //Invitar contacto por email
  sendEmailContact(idBrokerhood, email) {
    return fetch(`${URL_SERVICE}/11`, {
      method: "POST",
      body: JSON.stringify({
        bkh_id: idBrokerhood,
        contact_email: email,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  //Creacion del brokerhood y el mienbro admin
  addMemberBroker(idBrokerhood, nmBrokerhood, brk_id, email) {
    return fetch(`${URL_SERVICE}/12`, {
      method: "POST",
      body: JSON.stringify({
        bkh_id: idBrokerhood,
        bkh_name: nmBrokerhood,
        brk_id: brk_id,
        email: email,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  getTiposEstate(bkh_id) {
    return fetch(`${URL_SERVICE}/13`, {
      method: "POST",
      body: JSON.stringify({
        tipo: 2,
        bkh_id: bkh_id,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  //Creacion del Pedido
  createOrder(
    curBroker,
    tipoEstate,
    motivoEstate,
    areaEstate,
    loteEstate,
    curInicial,
    curFinal,
    alcobasEstate,
    banosEstate,
    parkingEstate,
    addressEstate,
    describeEstate,
    bkhComunas
  ) {
    return fetch(`${URL_SERVICE}/15`, {
      method: "POST",
      body: JSON.stringify({
        bkh_id: 0,
        brk_id: curBroker.id,
        mbr_id: 0,
        tin_id: tipoEstate,
        mtv_id: motivoEstate,
        ord_area: areaEstate,
        ord_lote: loteEstate,
        ord_desde: curInicial,
        ord_hasta: curFinal,
        ord_alcobas: alcobasEstate,
        ord_banos: banosEstate,
        ord_parking: parkingEstate,
        ord_address: addressEstate,
        ord_nota: describeEstate,
        ord_zonas: bkhComunas,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  //Creacion del brokerhood y el mienbro admin
  updateMember(idMember, idBroker, idBHood, tipo) {
    return fetch(`${URL_SERVICE}/18`, {
      method: "POST",
      body: JSON.stringify({
        id: idMember,
        brk_id: idBroker,
        bkh_id: idBHood,
        estado: tipo,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", "Solicite Soporte")
      )
      .then((response) => response);
  }

  recoveryPassword(email_firebase) {
    let uploadData = new FormData();
    uploadData.append("email", email_firebase);
    uploadData.append("app_origen", 2);
    uploadData.append("app_version", cur_vrs);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_SERVICE}/103`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  uploadImagesOffer(numFotos, item, brk_id, ofr_id, ofr_image) {
    return fetch(`${URL_SERVICE}/108`, {
      method: "POST",
      body: JSON.stringify({
        numRgs: numFotos,
        item: item,
        brk_id: brk_id,
        ofr_id: ofr_id,
        ofr_image: ofr_image,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  uploadInventory(
    brk_id,
    zna_id,
    tin_id,
    mtv_nombre,
    ofr_address,
    ofr_area,
    ofr_lote,
    ofr_alcobas,
    ofr_banos,
    ofr_parking,
    ofr_precio,
    ofr_nota,
    ofr_ciudad,
    ofr_comuna,
    zna_nombre,
    tin_nombre,
    numRgs,
    curReg
  ) {
    return fetch(`${URL_SERVICE}/102`, {
      method: "POST",
      body: JSON.stringify({
        brk_id: Number.parseInt(brk_id),
        zna_id: Number.parseInt(zna_id),
        tin_id: Number.parseInt(tin_id),
        mtv_nombre: mtv_nombre,
        ofr_address: ofr_address,
        ofr_area: Number.parseFloat(ofr_area),
        ofr_lote: Number.parseFloat(ofr_lote),
        ofr_alcobas: Number.parseInt(ofr_alcobas),
        ofr_banos: Number.parseInt(ofr_banos),
        ofr_parking: Number.parseInt(ofr_parking),
        ofr_precio: Number.parseFloat(ofr_precio),
        ofr_nota: ofr_nota,
        ofr_ciudad: ofr_ciudad,
        ofr_comuna: ofr_comuna,
        zna_nombre: zna_nombre,
        tin_nombre: tin_nombre,
        numRgs: Number.parseInt(numRgs),
        curReg: Number.parseInt(curReg),
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  updateDetalleOffer(
    ofr_id,
    bkh_id,
    brk_id,
    ofr_tin_id,
    ofrArea,
    ofrLote,
    ofrPrecio,
    ofrAlcobas,
    ofrBanos,
    ofrParking,
    ofrAddress,
    ofrNota
  ) {
    return fetch(`${URL_SERVICE}/22`, {
      method: "POST",
      body: JSON.stringify({
        ofr_id: ofr_id,
        bkh_id: bkh_id,
        brk_id: brk_id,
        ofr_area: ofrArea,
        ofr_lote: ofrLote,
        ofr_precio: ofrPrecio,
        ofr_alcobas: ofrAlcobas,
        ofr_banos: ofrBanos,
        ofr_parking: ofrParking,
        ofr_address: ofrAddress,
        ofr_nota: ofrNota,
        ofr_latitud: null,
        ofr_latitudDelta: null,
        ofr_longitud: null,
        ofr_longitudDelta: null,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  deleteOfferOrder(id, idBroker, idBHood, tipo) {
    return fetch(`${URL_SERVICE}/21`, {
      method: "POST",
      body: JSON.stringify({
        id: id,
        brk_id: idBroker,
        bkh_id: idBHood,
        estado: tipo,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  uploadZonaSector(brk_id, dato, numRgs, curReg) {
    console.log(dato);
    return fetch(`${URL_SERVICE}/108`, {
      method: "POST",
      body: JSON.stringify({
        brk_id: brk_id,
        key: dato.key,
        ofr_ciudad: dato.ofr_ciudad,
        ofr_zna_cna_sct: dato.ofr_zna_cna_sct,
        ofr_barrio: dato.ofr_barrio,
        numRgs: numRgs,
        curReg: curReg,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  crearCiudad(brk_id, ciudadId, zonaId, data_input, idState) {
    return fetch(`${URL_SERVICE}/109`, {
      method: "POST",
      body: JSON.stringify({
        brk_id: brk_id,
        ciu_id: ciudadId,
        cmn_id: zonaId,
        nombre: data_input,
        id_state: idState,
        app_origen: 2,
        app_version: cur_vrs,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }
}

export default new SRV();
