import SRV from "./Service";
import * as firebase from "firebase";
import { showNotification } from "./showNotification";

export const reloadData = async () => {
  try {
    const curr_user = firebase.auth().currentUser.uid;
    console.log(curr_user);
    let datos = await SRV.getBroker(curr_user);
    if (datos) {
      return datos;
    } else {
      showNotification(
        "topRight",
        2,
        "SINCRONIZAR DATOS",
        "ERROR AL INTENTAR LA CONEXION... INTENTE MAS TARDE"
      );
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
