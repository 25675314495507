import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  Row,
  Col,
  Card,
  Typography,
  Tabs,
  Divider,
  Modal as ModalB,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faHandshake } from "@fortawesome/free-solid-svg-icons";
import Icon from "@mdi/react";
import {
  mdiAccountGroupOutline,
  mdiCityVariantOutline,
  mdiHomeCityOutline,
  mdiArrowCollapseAll,
  mdiBaseballBat,
  mdiHand,
  mdiStar,
} from "@mdi/js";
import { ExclamationCircleOutlined } from "@ant-design/icons";

//Componentes adicionales
import MainHeader from "../components/Header";
import OffersTable from "../components/Offers";
import BrokerHoodsTable from "../components/BrokerHoods";
import BrokerCathTable from "../components/BrokerCath";
import BrokerHitsTable from "../components/BrokerHits";
import ConOffersTable from "../components/ConOffers";
import OrdersTable from "../components/Orders";
import ConOrdersTable from "../components/ConOrders";

//Firebase
import firebase from "../utils/Firebase";
import "firebase/auth";

//Constantes
import { USER_INFO } from "../constants";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Imaganes y logos
import Logof from "../components/Footer";

//Estilos
import "./dashboard.scss";

export default function Dashboard(props) {
  const { setIsLoading, setIslogin } = props;
  const { Content, Footer } = Layout;
  const [activeKey, setActiveKey] = useState(new Date());

  //Para hacer redirect
  const history = useHistory();

  //useSelector para acceder al store
  const gestionGen = useSelector(
    (state) => state.broker.stateInfoBroker.gestion_gen[0]
  );

  useEffect(() => {
    const user = localStorage.getItem(USER_INFO);
    if (Number.parseInt(user) === 0) {
      history.push("/");
    }
  }, [activeKey]);

  const changeTab = (activeKey) => {
    setActiveKey(activeKey);
  };

  const { Title } = Typography;
  const { TabPane } = Tabs;
  const { confirm } = ModalB;

  const closeSesion = async () => {
    confirm({
      title: "CERRAR SESION",
      icon: <ExclamationCircleOutlined />,
      okText: "Cerrar BH",
      cancelText: "Cancelar",
      content: "Desea salir de BrokerHood?",
      onOk() {
        setIsLoading(true);
        firebase.auth().signOut();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <div style={{ height: "75" }}>
        <MainHeader setIsLoading={setIsLoading} curFecha={new Date()} />
      </div>
      <Layout
        className="site-layout"
        style={{ marginLeft: 0, marginTop: "5px" }}
      >
        <Content
          style={{
            margin: " 10px 16px 10px 10px",
            overflow: "initial",
          }}
        >
          <div className="site-card-wrapper">
            <Row gutter={16}>
              <Col span={4}>
                <Card
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "solid",
                    borderBottomColor: "#6A3E98",
                    borderBottomWidth: "0.8px",
                  }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faUsers}
                        style={{ fontSize: 35, color: "#6A3E98" }}
                      />
                      <Title
                        level={2}
                        style={{
                          color: "#6A3E98",
                          margin: 0,
                          fontWeight: 700,
                        }}
                      >
                        {gestionGen.brokers_gen}
                      </Title>
                    </div>
                  }
                  bordered={false}
                >
                  <Title level={4} style={{ margin: 0, color: "#6A3E98" }}>
                    {"Brokers"}
                  </Title>
                </Card>
              </Col>
              <Col span={4}>
                <Card
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "solid",
                    borderBottomColor: "#4885ed",
                    borderBottomWidth: "0.8px",
                  }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Icon
                        path={mdiAccountGroupOutline}
                        size={"41px"}
                        color="#4885ed"
                      />
                      <Title
                        level={2}
                        style={{
                          color: "#4885ed",
                          margin: 0,
                          fontWeight: 700,
                        }}
                      >
                        {gestionGen.brokershood_gen}
                      </Title>
                    </div>
                  }
                  bordered={false}
                >
                  <Title level={4} style={{ margin: 0, color: "#4885ed" }}>
                    BrokerHoods
                  </Title>
                </Card>
              </Col>
              <Col span={4}>
                <Card
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "solid",
                    borderBottomColor: "#db3236",
                    borderBottomWidth: "0.8px",
                  }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Icon
                        path={mdiCityVariantOutline}
                        size={"41px"}
                        color="#db3236"
                      />
                      <Title
                        level={2}
                        style={{
                          color: "#db3236",
                          margin: 0,
                          fontWeight: 700,
                        }}
                      >
                        {gestionGen.ofertas_gen}
                      </Title>
                    </div>
                  }
                  bordered={false}
                >
                  <Title level={4} style={{ margin: 0, color: "#db3236" }}>
                    Ofertas Activas
                  </Title>
                </Card>
              </Col>
              <Col span={4}>
                <Card
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "solid",
                    borderBottomColor: "#65A4CA",
                    borderBottomWidth: "0.8px",
                  }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Icon
                        path={mdiHomeCityOutline}
                        size={"41px"}
                        color="#65A4CA"
                      />
                      <Title
                        level={2}
                        style={{
                          color: "#65A4CA",
                          margin: 0,
                          fontWeight: 700,
                        }}
                      >
                        {gestionGen.pedidos_gen}
                      </Title>
                    </div>
                  }
                  bordered={false}
                >
                  <Title level={4} style={{ margin: 0, color: "#65A4CA" }}>
                    Pedidos Activos
                  </Title>
                </Card>
              </Col>
              <Col span={4}>
                <Card
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "solid",
                    borderBottomColor: "#F4B400",
                    borderBottomWidth: "0.8px",
                  }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Icon
                        path={mdiArrowCollapseAll}
                        size={"41px"}
                        color="#F4B400"
                      />
                      <Title
                        level={2}
                        style={{
                          color: "#F4B400",
                          margin: 0,
                          fontWeight: 700,
                        }}
                      >
                        {gestionGen.catches_gen}
                      </Title>
                    </div>
                  }
                  bordered={false}
                >
                  <Title level={4} style={{ margin: 0, color: "#F4B400" }}>
                    Matches Activos
                  </Title>
                </Card>
              </Col>
              <Col span={4}>
                <Card
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "solid",
                    borderBottomColor: "#3cba54",
                    borderBottomWidth: "0.8px",
                  }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faHandshake}
                        style={{ fontSize: 35, color: "#3cba54" }}
                      />
                      <Title
                        level={2}
                        style={{
                          color: "#3cba54",
                          margin: 0,
                          fontWeight: 700,
                        }}
                      >
                        {gestionGen.hits_gen}
                      </Title>
                    </div>
                  }
                  bordered={false}
                >
                  <Title level={4} style={{ margin: 0, color: "#3cba54" }}>
                    Ventas Cerradas
                  </Title>
                </Card>
              </Col>
            </Row>
          </div>
          <Divider
            style={{
              marginTop: 15,
              marginBottom: 2,
              padding: 0,
            }}
          />
          <div
            style={{
              backgroundColor: "#ffffff",
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <Tabs
              defaultActiveKey={activeKey}
              onChange={changeTab}
              tabBarGutter={40}
            >
              <TabPane
                tab={
                  <span>
                    <Icon path={mdiAccountGroupOutline} size={"75"} />
                    <Title
                      level={4}
                      style={{
                        color: activeKey === "1" ? "#6A3E98" : "#333333",
                      }}
                    >
                      BrokerHoods
                    </Title>
                  </span>
                }
                key="1"
              >
                <BrokerHoodsTable />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <Icon path={mdiCityVariantOutline} size={"75"} />
                    <Title
                      level={4}
                      style={{
                        color: activeKey === "2" ? "#6A3E98" : "#333333",
                      }}
                    >
                      Mis Ofertas
                    </Title>
                  </span>
                }
                key="2"
              >
                <OffersTable setIsLoading={setIsLoading} />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <Icon path={mdiHomeCityOutline} size={"75"} />
                    <Title
                      level={4}
                      style={{
                        color: activeKey === "3" ? "#6A3E98" : "#333333",
                      }}
                    >
                      Mis Pedidos
                    </Title>
                  </span>
                }
                key="3"
              >
                <OrdersTable />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <Icon path={mdiArrowCollapseAll} size={"75"} />
                    <Title
                      level={4}
                      style={{
                        color: activeKey === "4" ? "#6A3E98" : "#333333",
                      }}
                    >
                      Con Ofertas
                    </Title>
                  </span>
                }
                key="4"
              >
                <ConOffersTable />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <Icon path={mdiArrowCollapseAll} size={"75"} />
                    <Title
                      level={4}
                      style={{
                        color: activeKey === "5" ? "#6A3E98" : "#333333",
                      }}
                    >
                      Con Pedidos
                    </Title>
                  </span>
                }
                key="5"
              >
                <ConOrdersTable />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <Icon path={mdiBaseballBat} size={"75"} />
                    <Title
                      level={4}
                      style={{
                        color: activeKey === "6" ? "#6A3E98" : "#333333",
                      }}
                    >
                      Mis Broker Hits
                    </Title>
                  </span>
                }
                key="6"
              >
                <BrokerHitsTable />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <Icon path={mdiHand} size={"75"} />
                    <Title
                      level={4}
                      style={{
                        color: activeKey === "7" ? "#6A3E98" : "#333333",
                      }}
                    >
                      Mis Catches
                    </Title>
                  </span>
                }
                key="7"
              >
                <BrokerCathTable />
              </TabPane>
            </Tabs>
          </div>
        </Content>
        <Footer style={{ textAlign: "center", bottom: 0 }}>
          <Logof />
        </Footer>
      </Layout>
    </Layout>
  );
}
