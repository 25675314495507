import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Input,
  Button,
  Space,
  Divider,
  Typography,
  message,
} from "antd";

//Iconos y constantes
import {
  SaveOutlined,
  EditOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import Icon from "@mdi/react";
import { mdiMapMarkerRadiusOutline } from "@mdi/js";

// Componentes
import SRV from "../../utils/Service";
import { reloadData } from "../../utils/ReloadInfo";
import { showNotification } from "../../utils/showNotification";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateDataBrokerAction } from "../../actions/brokerActions";

export default function CrearCiudad(props) {
  const {
    ciudades,
    dataComunas,
    dataZonas,
    curBroker,
    closeModal,
    setIsLoading,
    varEffec,
    currentState,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [ofrCiudad, setOfrCiudad] = useState("");
  const [ofrZona, setOfrZona] = useState("");
  const [ofrBarrio, setOfrBarrio] = useState("");
  const [columnsTabla, setColumnsTabla] = useState([]);
  const [tituloModal, setTituloModal] = useState("");
  const [myFormInput, SetMyFormInput] = useState("ciudad");

  const [data, setData] = useState(ciudades);
  const [dataHs, setDataHs] = useState(ciudades);
  const [ciudadId, setCiudadId] = useState(0);
  const [zonaId, setZonaId] = useState();
  const [searchCiudad, setSearchCiudad] = useState("");
  const [searchZonas, setSearchZonas] = useState("");
  const [searchBarrios, setSearchBarrios] = useState("");
  const { Title } = Typography;
  const { Search } = Input;

  var titulo_ciudad =
    "GESTIONAR UBICACIONES EN " +
    currentState.sta_nombre +
    "  " +
    currentState.sta_pais;
  var titulo_zonas = "GESTIONAR ZONAS O COMUNAS";
  var titulo_barrios = "GESTIONAR BARRIOS O SECTORES";
  var brk_id = curBroker.id;

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateData = (state) => dispatch(updateDataBrokerAction(state));

  useEffect(() => {
    //Columnas de la tabla para Ciudades
    setTituloModal(titulo_ciudad);
    SetMyFormInput("ciudad");
    setColumnsTabla(columnsvaluecities);
    setData(ciudades);
  }, [varEffec]);

  useEffect(() => {
    if (ofrCiudad || searchCiudad) {
      const filteredCiudad = ciudades.filter((ciudad) => {
        let ciudadLowercase = ciudad.label.toLowerCase();

        if (ofrCiudad) {
          let searchLowercase = ofrCiudad.toLowerCase();
          return ciudadLowercase.indexOf(searchLowercase) > -1;
        } else if (searchCiudad) {
          let searchLowercase = searchCiudad.toLowerCase();
          return ciudadLowercase.indexOf(searchLowercase) > -1;
        }
      });
      setData(filteredCiudad);
    } else {
      setData(ciudades);
    }
  }, [ofrCiudad, searchCiudad]);

  useEffect(() => {
    if (ofrZona || searchZonas) {
      const filteredZona = dataHs.filter((zona) => {
        let zonaLowercase = zona.nombre.toLowerCase();

        if (ofrZona) {
          let searchLowercase = ofrZona.toLowerCase();
          return zonaLowercase.indexOf(searchLowercase) > -1;
        } else if (searchZonas) {
          let searchLowercase = searchZonas.toLowerCase();
          return zonaLowercase.indexOf(searchLowercase) > -1;
        }
      });
      setData(filteredZona);
    } else {
      setData(dataHs);
    }
  }, [ofrZona, searchZonas]);

  useEffect(() => {
    if (ofrBarrio || searchBarrios) {
      const filteredBarrio = dataHs.filter((barrio) => {
        let barrioLowercase = barrio.label.toLowerCase();

        if (ofrBarrio) {
          let searchLowercase = ofrBarrio.toLowerCase();
          return barrioLowercase.indexOf(searchLowercase) > -1;
        } else if (searchBarrios) {
          let searchLowercase = searchBarrios.toLowerCase();
          return barrioLowercase.indexOf(searchLowercase) > -1;
        }
      });
      setData(filteredBarrio);
    } else {
      setData(dataHs);
    }
  }, [ofrBarrio, searchBarrios]);

  const syncData = async () => {
    let reLoad = await reloadData();
    if (reLoad) {
      updateData(reLoad);
      return true;
    } else {
      return false;
    }
  };

  // Encabezado de Ciudades
  const columnsvaluecities = [
    {
      title: "Ciudades",
      dataIndex: "label",
      key: "label",
      width: "45%",
    },
    {
      title: "Zonas",
      key: "action",
      width: "8%",
      align: "center",
      render: (text, record) => (
        <Button
          type="primary"
          shape="round"
          icon={<EditOutlined />}
          onClick={() => MostrarZonas(record.value, record.value, zonaId, null)}
        ></Button>
      ),
    },
  ];

  // Encabezados de Zonas
  const columnsvaluezonas = [
    {
      title: "Zonas o Comunas",
      dataIndex: "nombre",
      key: "nombre",
      width: "45%",
    },
    {
      title: "Barrios",
      key: "action",
      width: "8%",
      align: "center",
      render: (text, record) => (
        <Button
          type="primary"
          shape="round"
          icon={<EditOutlined />}
          onClick={() => MostrarBarrios(record.id, record.ciu_id, zonaId, null)}
        ></Button>
      ),
    },
  ];

  // Encabezados de Barrios
  const columnsvaluebarrios = [
    {
      title: "Barrios o Sectores",
      dataIndex: "label",
      key: "label",
      width: "45%",
    },
  ];

  const MostrarZonas = async (record, ciudad, zona, barrio) => {
    setTituloModal(titulo_zonas);
    SetMyFormInput("zonas");
    setCiudadId(ciudad);
    setColumnsTabla(columnsvaluezonas);
    const newComunas = dataComunas.filter((comuna) => {
      return Number.parseInt(comuna.ciu_id) === Number.parseInt(record);
    });
    setData(newComunas);
    setDataHs(newComunas);
  };

  const MostrarBarrios = async (record, ciudad, zona, barrio) => {
    setTituloModal(titulo_barrios);
    SetMyFormInput("barrios");
    setCiudadId(ciudad);
    setZonaId(record);
    setColumnsTabla(columnsvaluebarrios);
    const newZonas = dataZonas.filter((zonas) => {
      return Number.parseInt(zonas.cmn_id) === Number.parseInt(record);
    });
    setData(newZonas);
    setDataHs(newZonas);
  };

  const BackView = async () => {
    // accion para vista anterior

    // Valido en que vista esta actualmente activa
    if (myFormInput === "zonas") {
      // si estoy en vista zonas vuelvo a Ciudad

      setTituloModal(titulo_ciudad);
      SetMyFormInput("ciudad");
      setColumnsTabla(columnsvaluecities);
      setData(ciudades);
    } else if (myFormInput === "barrios") {
      // si estoy en vista barrios vuelvo a Zonas

      MostrarZonas(ciudadId, ciudadId, zonaId, null);
    }
  };

  const onChangeSrcCiudad = (e) => {
    setSearchCiudad(e.target.value);
  };

  const onChangeSrcZona = (e) => {
    setSearchZonas(e.target.value);
  };

  const onChangeSrcBarrio = (e) => {
    setSearchBarrios(e.target.value);
  };

  const onChangeOfrCiudad = (e) => {
    setOfrCiudad(e.target.value);
  };

  const onChangeOfrZona = (e) => {
    setOfrZona(e.target.value);
  };

  const onChangeOfrBarrio = (e) => {
    setOfrBarrio(e.target.value);
  };

  const crearNewCity = async () => {
    if (!ofrCiudad) {
      message.error("Debe ingresar Ciudad!");
      return false;
    }
    let val_data = await SRV.crearCiudad(
      brk_id,
      ciudadId,
      zonaId,
      ofrCiudad,
      currentState.value
    );
    if (val_data.type > 0) {
      let dataSync = syncData();
      if (dataSync) {
        showNotification(
          "topRight",
          4,
          "CREAR UBICACION",
          "Proceso realizado de manera EXITOSA"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CREAR UBICACION",
          "POR FAVOR SINCRONICE SUS DATOS"
        );
      }
    } else {
      showNotification(
        "topRight",
        2,
        "CREAR UBICACION",
        "SE PRODUJO UN ERROR EL REGISTRAR LA CIUDAD"
      );
    }
    setOfrCiudad("");
    closeModal();
  };

  const crearNewZona = async () => {
    if (!ofrZona) {
      message.error("Debe ingresar Zona!");
      return false;
    }
    var val_data = await SRV.crearCiudad(
      brk_id,
      ciudadId,
      zonaId,
      ofrZona,
      currentState.value
    );
    if (val_data.type > 0) {
      let dataSync = syncData();
      if (dataSync) {
        showNotification(
          "topRight",
          4,
          "CREAR UBICACION",
          "Proceso realizado de manera EXITOSA"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CREAR UBICACION",
          "POR FAVOR SINCRONICE SUS DATOS"
        );
      }
    } else {
      showNotification(
        "topRight",
        2,
        "CREAR UBICACION",
        "SE PRODUJO UN ERROR EL REGISTRAR LA ZONA"
      );
    }
    setOfrZona("");
    closeModal();
  };

  const crearNewBarrio = async () => {
    if (!ofrBarrio) {
      message.error("Debe ingresar Barrio!");
      return false;
    }
    var val_data = await SRV.crearCiudad(
      brk_id,
      ciudadId,
      zonaId,
      ofrBarrio,
      currentState.value
    );
    if (val_data.type > 0) {
      let dataSync = syncData();
      if (dataSync) {
        showNotification(
          "topRight",
          4,
          "CREAR UBICACION",
          "Proceso realizado de manera EXITOSA"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CREAR UBICACION",
          "POR FAVOR SINCRONICE SUS DATOS"
        );
      }
    } else {
      showNotification(
        "topRight",
        2,
        "CREAR UBICACION",
        "SE PRODUJO UN ERROR EL REGISTRAR EL BARRIO"
      );
    }
    setOfrBarrio("");
    closeModal();
  };

  return (
    <>
      {myFormInput === "ciudad" ? (
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "center",
            color: "#6A3E98",
          }}
        >
          {tituloModal}
        </Title>
      ) : (
        <Space align="center" size="large">
          <Button
            shape="round"
            type="link"
            icon={<ArrowLeftOutlined style={{ fontSize: 30 }} />}
            onClick={BackView}
          ></Button>
          <Title
            level={4}
            style={{
              padding: 2,
              margin: 2,
              fontWeight: 600,
              textAlign: "center",
              color: "#6A3E98",
            }}
          >
            {tituloModal}
          </Title>
        </Space>
      )}

      <Divider style={{ marginTop: 3, paddingTop: 3, marginBottom: 5 }} />

      {myFormInput === "ciudad" ? (
        <Search
          placeholder="Buscar Ciudad"
          onChange={onChangeSrcCiudad}
          enterButton
          allowClear
        />
      ) : myFormInput === "zonas" ? (
        <Search
          placeholder="Buscar Zona"
          onChange={onChangeSrcZona}
          enterButton
          allowClear
        />
      ) : (
        <Search
          placeholder="Buscar Barrios"
          onChange={onChangeSrcBarrio}
          enterButton
          allowClear
        />
      )}

      <Table
        columns={columnsTabla}
        dataSource={data}
        pagination={{ pageSize: 10 }}
        style={{ padding: 3, marginTop: 5 }}
      />
      <Divider
        style={{ marginTop: 3, paddingTop: 3, padding: 3, marginBottom: 3 }}
      />
      <div
        style={{
          display: "flex",
          alignContent: "center",
          marginBottom: "20px",
        }}
      >
        <Space>
          {myFormInput === "ciudad" ? (
            <>
              <Form.Item>
                <Input
                  style={{ width: "600px" }}
                  value={ofrCiudad}
                  onChange={onChangeOfrCiudad}
                  placeholder={"Ingrese Nueva Ciudad"}
                  required
                  prefix={
                    <Icon
                      path={mdiMapMarkerRadiusOutline}
                      size={"20"}
                      color="#6A3E98"
                      style={{ marginRight: 10 }}
                    />
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  block
                  onClick={crearNewCity}
                  loading={loadingButton}
                >
                  <SaveOutlined />
                </Button>
              </Form.Item>
            </>
          ) : myFormInput === "zonas" ? (
            <>
              <Form.Item>
                <Input
                  style={{ width: "600px" }}
                  value={ofrZona}
                  onChange={onChangeOfrZona}
                  placeholder={"Ingrese Nueva Zona"}
                  required
                  prefix={
                    <Icon
                      path={mdiMapMarkerRadiusOutline}
                      size={"20"}
                      color="#6A3E98"
                      style={{ marginRight: 10 }}
                    />
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  block
                  onClick={crearNewZona}
                  loading={loadingButton}
                >
                  <SaveOutlined />
                </Button>
              </Form.Item>
            </>
          ) : myFormInput === "barrios" ? (
            <>
              <Form.Item>
                <Input
                  style={{ width: "600px" }}
                  value={ofrBarrio}
                  onChange={onChangeOfrBarrio}
                  placeholder={"Ingrese Nuevo Barrio"}
                  required
                  prefix={
                    <Icon
                      path={mdiMapMarkerRadiusOutline}
                      size={"20"}
                      color="#6A3E98"
                      style={{ marginRight: 10 }}
                    />
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  block
                  onClick={crearNewBarrio}
                  loading={loadingButton}
                >
                  <SaveOutlined />
                </Button>
              </Form.Item>
            </>
          ) : (
            <></>
          )}
        </Space>
      </div>
    </>
  );
}
