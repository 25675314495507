import React, { useState } from "react";
import { Button, Divider, Upload, Modal, Typography, message } from "antd";

import { UploadOutlined, PlusOutlined } from "@ant-design/icons";

//Estilos
import "./AddFotosOffer.scss";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateDataBrokerAction } from "../../actions/brokerActions";

// Componentes
import SRV from "../../utils/Service";
import { showNotification } from "../../utils/showNotification";

export default function AddFotosOffer(props) {
  const { detalleOffer, closeModal, setIsLoading } = props;
  const [loadingButton, setLoadingButton] = useState(false);

  // Nuevos States
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileListB64, setFileListB64] = useState([]);

  const { Title } = Typography;

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateData = (state) => dispatch(updateDataBrokerAction(state));

  const gridStyle = {
    width: "20%",
    textAlign: "center",
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  /////////////////////////////////
  // GUARDO LAS IMAGENES
  const guardarImagenes = async () => {
    setLoadingButton(true);

    var brk_id = detalleOffer.brk_id;
    var ofr_id = detalleOffer.id;
    const numFotos = fileList.length;
    for (var i = 0; i < fileList.length; i++) {
      var ofr_image = fileList[i].url;
      let item = i + 1;

      var val_broker = await SRV.uploadImagesOffer(
        numFotos,
        item,
        brk_id,
        ofr_id,
        ofr_image
      );

      if (val_broker.type === 0) {
        showNotification("topRight", 2, "ERROR DE ACTUALIZACION", "Error");
        setIsLoading(false);
        closeModal();
        return false;
      }
    }

    updateData(val_broker.datos); // Actualizo Datos de Broker
    showNotification(
      4,
      "ACTUALIZACION EXITOSA",
      "Imagenes Actualizadas Exitosamente."
    );
    setFileList([]);
    setLoadingButton(false);
    closeModal();
    //window.location.reload();
  };

  // Accion para Obtener las nuevas imagenes
  const handleBefore = async (file) => {
    ///////////////////////////////////////////
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Solo se permiten imagenes JPG/PNG!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Solo esta permitido archivos de Menos 2MB!");
      return false;
    }
    ///////////////////////////////////////////

    let imgBase64 = await getBase64(file); // Obtengo la conversion de la Imagen a Base 64

    // Convierto la imagen en Base64 para el envio a Webservices
    //let imgb64end = imgBase64.split(";base64,"); // Para solo tomar el objeto [1] para la imagen base64 limpia

    // Seteo de Valores para el Modal
    setPreviewTitle(file.name);
    setPreviewImage(imgBase64);

    // le Creo un uid al Objeto de la Nueva Imagen
    let uid = ((Math.random() * 16) | 0).toString(16);

    // Creo nuevo Objeto con la Imagen Nueva
    let newImg = { uid: uid, name: file.name, status: "done", url: imgBase64 };
    setFileListB64([...fileListB64, { base64: imgBase64 }]); // Guarda la Imagen para base64 Final
    setFileList([...fileList, newImg]); // Guarda la nueva imagen
  };

  // Cambio de Imagen
  const handleChange = (file) => {
    //console.log(fileList);
    //console.log(fileListB64);
  };

  // Imagen Preview
  const handlePreview = async (file) => {
    setPreviewImage(file.url);
    setPreviewVisible(true);
    setPreviewTitle(file.name);
  };

  const handleCancel = () => setPreviewVisible(false);

  // Elimino Imagen
  const onRemove = (file) => {
    // Borrar el Objeto Principal
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    var removed = newFileList.splice(index, 1);
    setFileList(newFileList);
    console.log(index);

    // Borrar el Objeto Base64
    const newFileListB64 = fileListB64.slice();
    var removedB64 = newFileListB64.splice(index, 1);
    setFileListB64(newFileListB64);
  };

  // Mostar Boton Upload
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Subir</div>
    </div>
  );

  //Renderizacion del componente
  return (
    <div className="main-content-det">
      <div style={{ width: "100%" }}>
        <div style={{ textAlign: "center", marginTop: 5 }}>
          <Title
            level={4}
            style={{
              padding: 2,
              margin: 2,
              fontWeight: 600,
              textAlign: "center",
              color: "#6A3E98",
            }}
          >
            MODIFICAR IMAGENES
          </Title>
          <Divider style={{ marginTop: 3, paddingTop: 3, marginBottom: 5 }} />

          <Upload
            listType="picture-card"
            fileList={fileList}
            beforeUpload={handleBefore}
            onChange={handleChange}
            onPreview={handlePreview}
            onRemove={onRemove}
          >
            {fileList.length >= 10 ? null : uploadButton}
          </Upload>
          <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </div>
        <Divider
          style={{ marginTop: 3, paddingTop: 3, padding: 3, marginBottom: 3 }}
        />
        <div>
          <Button
            type="primary"
            shape="round"
            block
            style={{ display: fileList.length > 0 ? "block" : "none" }}
            onClick={guardarImagenes}
            loading={loadingButton}
          >
            <UploadOutlined /> GUARDAR IMAGENES
          </Button>
        </div>
      </div>
    </div>
  );
}
