import React, { useState, useEffect } from "react";
import { Card, Typography, Input, Alert, Button, Form, Tooltip } from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

import Icon from "@mdi/react";
import {
  mdiFloorPlan,
  mdiMapOutline,
  mdiCashMultiple,
  mdiBedOutline,
  mdiToilet,
  mdiCar,
  mdiMapMarkerRadiusOutline,
} from "@mdi/js";

//Estilos
import "./EditDetalleOfr.scss";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateDataBrokerAction } from "../../actions/brokerActions";

// Componentes
import SRV from "../../utils/Service";
import { showNotification } from "../../utils/showNotification";

export default function EditDetalleOfr(props) {
  const { detalleOffer, closeModal } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [msgAlert, setMsgAlert] = useState("");
  const [ofrNota, setOfrNota] = useState("");
  const [ofrArea, setOfrArea] = useState(0);
  const [ofrLote, setOfrLote] = useState(0);
  const [ofrAlcobas, setOfrAlcobas] = useState(0);
  const [ofrBanos, setOfrBanos] = useState(0);
  const [ofrParking, setOfrParking] = useState(0);
  const [ofrPrecio, setOfrPrecio] = useState(0);
  const [ofrAddress, setOfrAddress] = useState("");
  const { Title, Text } = Typography;
  const { TextArea } = Input;

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateData = (state) => dispatch(updateDataBrokerAction(state));

  useEffect(() => {
    (async () => {
      setOfrNota(detalleOffer.ofr_nota);
      setOfrArea(detalleOffer.ofr_area);
      setOfrLote(detalleOffer.ofr_lote);
      setOfrAlcobas(detalleOffer.ofr_alcobas);
      setOfrBanos(detalleOffer.ofr_banos);
      setOfrParking(detalleOffer.ofr_parking);
      setOfrPrecio(detalleOffer.ofr_precio);
      setOfrAddress(detalleOffer.ofr_address);
    })();
  }, [detalleOffer.id]);

  const gridStyle = {
    width: "33.3%",
    textAlign: "center",
    borderWidth: "0px",
  };

  const onChangeOfrNota = (e) => {
    setOfrNota(e.target.value);
  };

  const onChangeOfrArea = (e) => {
    if (Number.isNaN(Number.parseFloat(e.target.value))) {
      setOfrArea(0);
      setMsgAlert("VALOR INCORRECTO PARA EL AREA");
      setVisibleAlert(true);
      return false;
    }
    setOfrArea(Number.parseFloat(e.target.value));
    setVisibleAlert(false);
  };

  const onChangeOfrLote = (e) => {
    if (Number.isNaN(Number.parseFloat(e.target.value))) {
      setOfrLote(0);
      setMsgAlert("VALOR INCORRECTO PARA EL LOTE");
      setVisibleAlert(true);
      return false;
    }
    setOfrLote(Number.parseFloat(e.target.value));
    setVisibleAlert(false);
  };

  const onChangeOfrPrecio = (e) => {
    if (Number.isNaN(Number.parseFloat(e.target.value))) {
      setOfrPrecio(0);
      setMsgAlert("VALOR INCORRECTO PARA EL PRECIO");
      setVisibleAlert(true);
      return false;
    }
    setOfrPrecio(Number.parseFloat(e.target.value));
    setVisibleAlert(false);
  };

  const onChangeOfrAlcobas = (e) => {
    if (Number.isNaN(Number.parseInt(e.target.value))) {
      setOfrAlcobas(0);
      setMsgAlert("VALOR INCORRECTO PARA LAS ALCOBAS");
      setVisibleAlert(true);
      return false;
    }
    setOfrAlcobas(Number.parseInt(e.target.value));
    setVisibleAlert(false);
  };

  const onChangeOfrBanos = (e) => {
    if (Number.isNaN(Number.parseInt(e.target.value))) {
      setOfrBanos(0);
      setMsgAlert("VALOR INCORRECTO PARA LOS BAÑOS");
      setVisibleAlert(true);
      return false;
    }
    setOfrBanos(Number.parseInt(e.target.value));
    setVisibleAlert(false);
  };

  const onChangeOfrParking = (e) => {
    if (Number.isNaN(Number.parseInt(e.target.value))) {
      setOfrParking(0);
      setMsgAlert("VALOR INCORRECTO PARA PARKING");
      setVisibleAlert(true);
      return false;
    }
    setOfrParking(Number.parseInt(e.target.value));
    setVisibleAlert(false);
  };

  const onChangeOfrAddress = (e) => {
    setOfrAddress(e.target.value);
  };

  /////////////////////////////////
  // GUARDO CAMBIOS
  const guardarCambios = async () => {
    setLoadingButton(true);

    var bkh_id = detalleOffer.bkh_id;
    var brk_id = detalleOffer.brk_id;
    var ofr_id = detalleOffer.id;
    var ofr_tin_id = detalleOffer.tin_id;

    let val_ofr = await SRV.updateDetalleOffer(
      ofr_id,
      bkh_id,
      brk_id,
      ofr_tin_id,
      ofrArea,
      ofrLote,
      ofrPrecio,
      ofrAlcobas,
      ofrBanos,
      ofrParking,
      ofrAddress,
      ofrNota
    );

    if (val_ofr.type > 0) {
      updateData(val_ofr.datos); // Actualizo Datos de Broker
      showNotification(
        "topRight",
        4,
        "ACTUALIZACION EXITOSA",
        "Detalles Actualizados Exitosamente."
      );
      setLoadingButton(false);
      closeModal();
    } else {
      showNotification("topRight", 2, "ERROR DE ACTUALIZACION", "Error");
    }
  };

  //Renderizacion del componente
  return (
    <div className="main-content-det">
      <form
        style={{
          backgroundColor: "#ffffff",
          marginTop: "5px",
          width: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          <div style={{ margin: "5px", padding: "5px" }}>
            <Card
              style={{ padding: "5px" }}
              title={
                <div>
                  <Title
                    level={4}
                    style={{
                      padding: 2,
                      margin: 2,
                      fontWeight: 600,
                      textAlign: "center",
                      color: "#6A3E98",
                    }}
                  >
                    {detalleOffer.mtv_nombre === "VENDER"
                      ? detalleOffer.tin_nombre + " PARA LA VENTA "
                      : detalleOffer.tin_nombre +
                        " PARA " +
                        detalleOffer.mtv_nombre}
                  </Title>
                  <Title
                    level={4}
                    style={{
                      padding: 2,
                      margin: 2,
                      fontWeight: 600,
                      textAlign: "center",
                      color: "#6A3E98",
                    }}
                  >
                    {detalleOffer.zna_nombre}
                  </Title>
                  {visibleAlert ? (
                    <Alert
                      message={msgAlert}
                      type="error"
                      style={{ marginTop: "15px", marginBottom: "15px" }}
                    />
                  ) : null}
                  <Form.Item>
                    <Input
                      value={ofrAddress}
                      onChange={onChangeOfrAddress}
                      prefix={
                        <Icon
                          path={mdiMapMarkerRadiusOutline}
                          size={"20"}
                          color="#6A3E98"
                          style={{ marginRight: 10 }}
                        />
                      }
                    />
                  </Form.Item>

                  <Form.Item>
                    <TextArea
                      rows={4}
                      value={ofrNota}
                      placeholder={"DESCRIPCIóN"}
                      onChange={onChangeOfrNota}
                      allowClear
                    />
                  </Form.Item>
                </div>
              }
            >
              <Card.Grid style={gridStyle}>
                <span>
                  <Tooltip
                    placement="topLeft"
                    title="Area del Inmueble"
                    color={"#6A3E98"}
                  >
                    <Input
                      size="large"
                      value={ofrArea}
                      placeholder="Area"
                      onChange={onChangeOfrArea}
                      maxLength={10}
                      prefix={
                        <Icon
                          path={mdiFloorPlan}
                          suffix={"m"}
                          size={"40"}
                          color="#6A3E98"
                          style={{ marginRight: 30 }}
                        />
                      }
                    />
                  </Tooltip>
                </span>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                <span>
                  <Tooltip
                    placement="topLeft"
                    title="Area del Lote (SI APLICA)"
                    color={"#6A3E98"}
                  >
                    <Input
                      size="large"
                      value={ofrLote}
                      onChange={onChangeOfrLote}
                      maxLength={10}
                      prefix={
                        <Icon
                          path={mdiMapOutline}
                          size={"40"}
                          color="#6A3E98"
                          style={{ marginRight: 30 }}
                        />
                      }
                    />
                  </Tooltip>
                </span>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                <span>
                  <Tooltip
                    placement="topLeft"
                    title="Precio del Inmueble"
                    color={"#6A3E98"}
                  >
                    <Input
                      size="large"
                      value={ofrPrecio}
                      onChange={onChangeOfrPrecio}
                      maxLength={8}
                      prefix={
                        <Icon
                          path={mdiCashMultiple}
                          suffix={"m"}
                          size={"40"}
                          color="#6A3E98"
                          style={{ marginRight: 30 }}
                        />
                      }
                    />
                  </Tooltip>
                </span>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                <span>
                  <Tooltip
                    placement="topLeft"
                    title="Alcobas"
                    color={"#6A3E98"}
                  >
                    <Input
                      size="large"
                      value={ofrAlcobas}
                      onChange={onChangeOfrAlcobas}
                      maxLength={4}
                      prefix={
                        <Icon
                          path={mdiBedOutline}
                          size={"40"}
                          color="#6A3E98"
                          style={{ marginRight: 30 }}
                        />
                      }
                    />
                  </Tooltip>
                </span>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                <span>
                  <Tooltip placement="topLeft" title="Baños" color={"#6A3E98"}>
                    <Input
                      size="large"
                      value={ofrBanos}
                      onChange={onChangeOfrBanos}
                      maxLength={4}
                      prefix={
                        <Icon
                          path={mdiToilet}
                          size={"40"}
                          color="#6A3E98"
                          style={{ marginRight: 30 }}
                        />
                      }
                    />
                  </Tooltip>
                </span>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                <span>
                  <Tooltip
                    placement="topLeft"
                    title="Parqueaderos"
                    color={"#6A3E98"}
                  >
                    <Input
                      size="large"
                      value={ofrParking}
                      onChange={onChangeOfrParking}
                      maxLength={4}
                      prefix={
                        <Icon
                          path={mdiCar}
                          size={"40"}
                          color="#6A3E98"
                          style={{ marginRight: 30 }}
                        />
                      }
                    />
                  </Tooltip>
                </span>
              </Card.Grid>
            </Card>
          </div>
          <div>
            <Button
              type="primary"
              shape="round"
              size="large"
              block
              onClick={guardarCambios}
              loading={loadingButton}
            >
              <SaveOutlined /> GUARDAR
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
