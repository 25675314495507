import React, { useState } from "react";
import {
  Typography,
  Input,
  Alert,
  Button,
  Popconfirm,
  Select,
  Divider,
  Tooltip,
} from "antd";

import { SaveOutlined } from "@ant-design/icons";

import Icon from "@mdi/react";
import {
  mdiFloorPlan,
  mdiMapOutline,
  mdiCashMultiple,
  mdiBedOutline,
  mdiToilet,
  mdiCar,
  mdiMapMarkerRadiusOutline,
} from "@mdi/js";

//Estilos
import "./CreateOrder.scss";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateDataBrokerAction } from "../../actions/brokerActions";

// Componentes
import SRV from "../../utils/Service";
import { reloadData } from "../../utils/ReloadInfo";
import { showNotification } from "../../utils/showNotification";

export default function CrearOrder(props) {
  const {
    curBroker,
    closeModal,
    dataCiudades,
    dataComunas,
    dataZonas,
    dataTiposInm,
    currentState,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [msgAlert, setMsgAlert] = useState("");
  const [zonas, setZonas] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [comunas, setComunas] = useState([]);
  const [curComuna, setCurComuna] = useState([]);
  const [curZona, setCurZona] = useState([]);
  const [cityId, setCityId] = useState(0);
  const [cmnaId, setCmnaId] = useState(0);
  const [znaId, setZnaId] = useState(0);
  const [mtvId, setMtvId] = useState(0);
  const [tinId, setTinId] = useState(0);
  const [ofrNota, setOfrNota] = useState("");
  const [ofrArea, setOfrArea] = useState("");
  const [ofrLote, setOfrLote] = useState("");
  const [ofrAlcobas, setOfrAlcobas] = useState(0);
  const [ofrBanos, setOfrBanos] = useState(0);
  const [ofrParking, setOfrParking] = useState(0);
  const [ofrPrecio, setOfrPrecio] = useState("");
  const [ofrPrecioMax, setOfrPrecioMax] = useState("");
  const [ofrAddress, setOfrAddress] = useState("");
  const { Title, Text } = Typography;
  const { TextArea } = Input;

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateData = (state) => dispatch(updateDataBrokerAction(state));

  const syncData = async () => {
    let reLoad = await reloadData();
    if (reLoad) {
      updateData(reLoad);
      return true;
    } else {
      return false;
    }
  };

  const { Option } = Select;

  const selectComuna = (value) => {
    setCityId(value);
    const newComunas = dataComunas.filter((comuna) => {
      return Number.parseInt(comuna.ciu_id) === Number.parseInt(value);
    });
    setComunas(newComunas);
    setCurComuna("Seleccione Zona o Comuna");
    setZonas([]);
  };

  const selectZona = (value) => {
    setCmnaId(value);
    setCurComuna(value);
    const newZonas = dataZonas.filter((zona) => {
      return Number.parseInt(zona.cmn_id) === Number.parseInt(value);
    });
    setZonas(newZonas);
  };

  const selectBarrio = (value) => {
    setZnaId(value);
    setCurZona(value);
  };
  const selectTipoInm = (value) => {
    setTinId(value);
  };
  const selectMotivo = (value) => {
    setMtvId(value);
  };

  const gridStyle = {
    width: "33.3%",
    textAlign: "center",
    borderWidth: "0px",
  };

  const gridStyle50 = {
    width: "50%",
    textAlign: "center",
    borderWidth: "0px",
  };

  const onChangeOfrNota = (e) => {
    setOfrNota(e.target.value);
  };

  const onChangeOfrArea = (e) => {
    if (Number.isNaN(Number.parseFloat(e.target.value))) {
      setOfrArea("");
      setMsgAlert("VALOR INCORRECTO PARA EL AREA");
      setVisibleAlert(true);
      return false;
    }
    setOfrArea(Number.parseFloat(e.target.value));
    setVisibleAlert(false);
  };

  const onChangeOfrLote = (e) => {
    if (Number.isNaN(Number.parseFloat(e.target.value))) {
      setOfrLote("");
      setMsgAlert("VALOR INCORRECTO PARA EL LOTE");
      setVisibleAlert(true);
      return false;
    }
    setOfrLote(Number.parseFloat(e.target.value));
    setVisibleAlert(false);
  };

  const onChangeOfrPrecio = (e) => {
    if (Number.isNaN(Number.parseFloat(e.target.value))) {
      setOfrPrecio("");
      setMsgAlert("VALOR INCORRECTO PARA EL PRECIO");
      setVisibleAlert(true);
      return false;
    }
    setOfrPrecio(Number.parseFloat(e.target.value));
    setVisibleAlert(false);
  };

  const onChangeOfrPrecioMax = (e) => {
    if (Number.isNaN(Number.parseFloat(e.target.value))) {
      setOfrPrecioMax("");
      setMsgAlert("VALOR INCORRECTO PARA EL PRECIO");
      setVisibleAlert(true);
      return false;
    }
    setOfrPrecioMax(Number.parseFloat(e.target.value));
    setVisibleAlert(false);
  };

  const onChangeOfrAlcobas = (e) => {
    if (Number.isNaN(Number.parseInt(e.target.value))) {
      setOfrAlcobas(0);
      setMsgAlert("VALOR INCORRECTO PARA LAS ALCOBAS");
      setVisibleAlert(true);
      return false;
    }
    setOfrAlcobas(Number.parseInt(e.target.value));
    setVisibleAlert(false);
  };

  const onChangeOfrBanos = (e) => {
    if (Number.isNaN(Number.parseInt(e.target.value))) {
      setOfrBanos(0);
      setMsgAlert("VALOR INCORRECTO PARA LOS BAÑOS");
      setVisibleAlert(true);
      return false;
    }
    setOfrBanos(Number.parseInt(e.target.value));
    setVisibleAlert(false);
  };

  const onChangeOfrParking = (e) => {
    if (Number.isNaN(Number.parseInt(e.target.value))) {
      setOfrParking(0);
      setMsgAlert("VALOR INCORRECTO PARA PARKING");
      setVisibleAlert(true);
      return false;
    }
    setOfrParking(Number.parseInt(e.target.value));
    setVisibleAlert(false);
  };

  const onChangeOfrAddress = (e) => {
    setOfrAddress(e.target.value);
  };

  /////////////////////////////////
  // GUARDO CAMBIOS
  const guardarCambios = async () => {
    if (
      mtvId === 0 ||
      tinId === 0 ||
      Number.parseFloat(ofrArea) === 0 ||
      Number.parseFloat(ofrPrecio) === 0 ||
      Number.parseFloat(ofrPrecioMax) === 0 ||
      curZona.length === 0
    ) {
      showNotification(
        "topRight",
        2,
        "CREAR PEDIDO",
        "Tipo de Inmueble, Tipo de Solicitud, Area, Precio y Descripcion son OBLIGATORIOS"
      );
    } else {
      if (Number.parseFloat(ofrPrecio) > Number.parseFloat(ofrPrecioMax)) {
        showNotification(
          "topRight",
          2,
          "CREAR PEDIDO",
          "PRECIOS MINIMO Y MAXIMO INCORRECTOS"
        );
      } else {
        setLoadingButton(true);
        // Creacion de Pedidos
        var bkhComunas = "";
        for (var i = 0; i < curZona.length; i++) {
          bkhComunas += Number.parseInt(curZona[i]) + "|";
        }

        let val_ofr = await SRV.createOrder(
          curBroker,
          tinId,
          mtvId,
          Number.parseFloat(ofrArea),
          Number.parseFloat(ofrLote),
          Number.parseFloat(ofrPrecio),
          Number.parseFloat(ofrPrecioMax),
          ofrAlcobas,
          ofrBanos,
          ofrParking,
          ofrAddress,
          ofrNota,
          bkhComunas
        );
        if (val_ofr.type > 0) {
          let dataSync = syncData();
          if (dataSync) {
            showNotification(
              "topRight",
              4,
              "CREAR PEDIDO",
              "Proceso realizado de manera EXITOSA"
            );
          } else {
            showNotification(
              "topRight",
              2,
              "CREAR PEDIDO",
              "POR FAVOR SINCRONICE SUS DATOS"
            );
          }
        } else {
          showNotification("topRight", 2, "CREAR PEDIDO", val_ofr.message);
        }
        setLoadingButton(false);
        closeModal();
      }
    }
  };

  //Renderizacion del componente
  return (
    <div className="main-content-det">
      <form
        style={{
          backgroundColor: "#ffffff",
          marginTop: "5px",
          width: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          <div style={{ margin: "5px" }}>
            <div>
              <Title
                level={4}
                style={{
                  padding: 2,
                  margin: 2,
                  fontWeight: 600,
                  textAlign: "center",
                  color: "#6A3E98",
                }}
              >
                {"CREAR NUEVO PEDIDO EN " +
                  currentState.sta_nombre +
                  " " +
                  currentState.sta_pais}
              </Title>
              <Divider
                style={{ marginTop: 3, paddingTop: 3, marginBottom: 5 }}
              />
              {visibleAlert ? (
                <Alert
                  message={msgAlert}
                  type="error"
                  style={{ marginTop: "15px", marginBottom: "15px" }}
                />
              ) : null}
              <div className="inputLine">
                <Select
                  size="large"
                  showSearch
                  style={{ width: "49%", marginRight: "2%" }}
                  placeholder="Seleccione Ciudad"
                  optionFilterProp="children"
                  onChange={selectComuna}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataCiudades.map((ciudad) => (
                    <Option key={ciudad.value}>{ciudad.label}</Option>
                  ))}
                </Select>
                <Select
                  size="large"
                  showSearch
                  style={{
                    width: "49%",
                  }}
                  placeholder="Seleccione Comuna o Sector"
                  optionFilterProp="children"
                  value={curComuna}
                  onChange={selectZona}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {comunas.map((comuna) => (
                    <Option key={comuna.id}>{comuna.nombre}</Option>
                  ))}
                </Select>
              </div>
              <div className="inputDiv">
                <Select
                  size="large"
                  mode="multiple"
                  placeholder={"Seleccione Barrio(s)"}
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  value={curZona}
                  onChange={selectBarrio}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {zonas.map((zona) => (
                    <Option key={zona.value}>{zona.label}</Option>
                  ))}
                </Select>
              </div>
              <div className="inputLine">
                <Select
                  size="large"
                  showSearch
                  style={{
                    width: "49%",
                    marginRight: "2%",
                  }}
                  placeholder="Seleccione Tipo de Inmueble"
                  optionFilterProp="children"
                  onChange={selectTipoInm}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataTiposInm.map((tipoInm) => (
                    <Option key={tipoInm.value}>{tipoInm.label}</Option>
                  ))}
                </Select>
                <Select
                  size="large"
                  showSearch
                  style={{ width: "49%" }}
                  placeholder="Seleccione Motivo"
                  optionFilterProp="children"
                  onChange={selectMotivo}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="0">Tipo de Solicitud</Option>
                  <Option value="1">COMPRA</Option>
                  <Option value="2">ALQUILER</Option>
                  <Option value="3">PERMUTA</Option>
                  <Option value="4">TODAS</Option>
                </Select>
              </div>
              <div className="inputDiv">
                <Input
                  size="large"
                  value={ofrAddress}
                  onChange={onChangeOfrAddress}
                  placeholder={"Sector - Unidad - Edificio"}
                  prefix={
                    <Icon
                      path={mdiMapMarkerRadiusOutline}
                      size={"20"}
                      color="#6A3E98"
                      style={{ marginRight: 10 }}
                    />
                  }
                />
              </div>
              <div className="inputDiv">
                <TextArea
                  rows={3}
                  value={ofrNota}
                  placeholder={"DESCRIPCIóN"}
                  onChange={onChangeOfrNota}
                  allowClear
                />
              </div>
              <div className="inputLine">
                <div style={{ width: "48%" }}>
                  <span>
                    <Tooltip
                      placement="topLeft"
                      title={"Precio Mínimo en " + currentState.sta_precio}
                      color={"#6A3E98"}
                    >
                      <Input
                        size="large"
                        placeholder={"Precio en " + currentState.sta_precio}
                        value={ofrPrecio}
                        onChange={onChangeOfrPrecio}
                        maxLength={12}
                        suffix={
                          <Icon
                            path={mdiCashMultiple}
                            suffix={"m"}
                            size={"25"}
                            color="#6A3E98"
                            style={{ marginRight: 2 }}
                          />
                        }
                      />
                    </Tooltip>
                  </span>
                </div>
                <div style={{ width: "48%" }}>
                  <span>
                    <Tooltip
                      placement="topLeft"
                      title={"Precio Máximo en " + currentState.sta_precio}
                      color={"#6A3E98"}
                    >
                      <Input
                        size="large"
                        placeholder={"Precio en " + currentState.sta_precio}
                        value={ofrPrecioMax}
                        onChange={onChangeOfrPrecioMax}
                        maxLength={8}
                        suffix={
                          <Icon
                            path={mdiCashMultiple}
                            suffix={"m"}
                            size={"25"}
                            color="#6A3E98"
                            style={{ marginRight: 2 }}
                          />
                        }
                      />
                    </Tooltip>
                  </span>
                </div>
              </div>
              <div className="inputLine">
                <div style={{ width: "25%" }}>
                  <span>
                    <Tooltip
                      placement="topLeft"
                      title="Area del Inmueble"
                      color={"#6A3E98"}
                    >
                      <Input
                        size="large"
                        value={ofrArea}
                        placeholder="Area"
                        onChange={onChangeOfrArea}
                        maxLength={10}
                        suffix={
                          <Icon
                            path={mdiFloorPlan}
                            suffix={"m"}
                            size={"25"}
                            color="#6A3E98"
                            style={{ marginRight: 2 }}
                          />
                        }
                      />
                    </Tooltip>
                  </span>
                </div>
                <div style={{ width: "25%" }}>
                  <span>
                    <Tooltip
                      placement="topLeft"
                      title="Area del Lote (SI APLICA)"
                      color={"#6A3E98"}
                    >
                      <Input
                        size="large"
                        placeholder="Area Lote"
                        value={ofrLote}
                        onChange={onChangeOfrLote}
                        maxLength={10}
                        suffix={
                          <Icon
                            path={mdiMapOutline}
                            size={"25"}
                            color="#6A3E98"
                            style={{ marginRight: 2 }}
                          />
                        }
                      />
                    </Tooltip>
                  </span>
                </div>
                <div style={{ width: "15%" }}>
                  <span>
                    <Tooltip
                      placement="topLeft"
                      title="Alcobas"
                      color={"#6A3E98"}
                    >
                      <Input
                        size="large"
                        value={ofrAlcobas}
                        onChange={onChangeOfrAlcobas}
                        maxLength={4}
                        suffix={
                          <Icon
                            path={mdiBedOutline}
                            size={"25"}
                            color="#6A3E98"
                            style={{ marginRight: 2 }}
                          />
                        }
                      />
                    </Tooltip>
                  </span>
                </div>
                <div style={{ width: "15%" }}>
                  <span>
                    <Tooltip
                      placement="topLeft"
                      title="Baños"
                      color={"#6A3E98"}
                    >
                      <Input
                        size="large"
                        value={ofrBanos}
                        onChange={onChangeOfrBanos}
                        maxLength={4}
                        suffix={
                          <Icon
                            path={mdiToilet}
                            size={"25"}
                            color="#6A3E98"
                            style={{ marginRight: 2 }}
                          />
                        }
                      />
                    </Tooltip>
                  </span>
                </div>
                <div style={{ width: "15%" }}>
                  <span>
                    <Tooltip
                      placement="topLeft"
                      title="Parqueaderos"
                      color={"#6A3E98"}
                    >
                      <Input
                        size="large"
                        value={ofrParking}
                        onChange={onChangeOfrParking}
                        maxLength={4}
                        suffix={
                          <Icon
                            path={mdiCar}
                            size={"25"}
                            color="#6A3E98"
                            style={{ marginRight: 2 }}
                          />
                        }
                      />
                    </Tooltip>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 25 }}>
            <Popconfirm
              placement="top"
              title="¿ DESEA CREAR EL PEDIDO ?"
              onConfirm={guardarCambios}
              okText="Confirmar"
              cancelText="Cancelar"
              icon={<SaveOutlined style={{ color: "#6A3E98" }} />}
            >
              <Button
                type="primary"
                shape="round"
                size="large"
                block
                loading={loadingButton}
              >
                <SaveOutlined /> CREAR PEDIDO
              </Button>
            </Popconfirm>
          </div>
        </div>
      </form>
    </div>
  );
}
