import React, { useState, useEffect } from "react";
import { Carousel, Card, Divider, Typography, Avatar, Row, Col } from "antd";

import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import { URL_OFFERT } from "../../constants";

import Icon from "@mdi/react";
import {
  mdiFloorPlan,
  mdiMapOutline,
  mdiBedOutline,
  mdiToilet,
  mdiCar,
  mdiCashMultiple,
} from "@mdi/js";

//Estilos
import "./DetalleOffer.scss";

export default function DetalleOffer(props) {
  const { detalleOffer, varEffec, currentState } = props;
  const [searchText, setSearchText] = useState("");
  const [fotosOffer, setFotosOffer] = useState([]);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState(0);

  const { Title, Text } = Typography;
  const { Meta } = Card;

  const gridStyle = {
    width: "100%",
    textAlign: "center",
  };

  useEffect(() => {
    const arrayUrls = [];
    const imageOfr = URL_OFFERT + detalleOffer.ofr_image1;
    arrayUrls.push(imageOfr);
    if (detalleOffer.ofr_image2) {
      const image2 = URL_OFFERT + detalleOffer.ofr_image2;
      arrayUrls.push(image2);
    }
    if (detalleOffer.ofr_image3) {
      const image3 = URL_OFFERT + detalleOffer.ofr_image3;
      arrayUrls.push(image3);
    }
    if (detalleOffer.ofr_image4) {
      const image4 = URL_OFFERT + detalleOffer.ofr_image4;
      arrayUrls.push(image4);
    }
    if (detalleOffer.ofr_image5) {
      const image5 = URL_OFFERT + detalleOffer.ofr_image5;
      arrayUrls.push(image5);
    }
    if (detalleOffer.ofr_image6) {
      const image6 = URL_OFFERT + detalleOffer.ofr_image6;
      arrayUrls.push(image6);
    }
    if (detalleOffer.ofr_image7) {
      const image7 = URL_OFFERT + detalleOffer.ofr_image7;
      arrayUrls.push(image7);
    }
    if (detalleOffer.ofr_image8) {
      const image8 = URL_OFFERT + detalleOffer.ofr_image8;
      arrayUrls.push(image8);
    }
    if (detalleOffer.ofr_image9) {
      const image9 = URL_OFFERT + detalleOffer.ofr_image9;
      arrayUrls.push(image9);
    }
    if (detalleOffer.ofr_image10) {
      const image10 = URL_OFFERT + detalleOffer.ofr_image10;
      arrayUrls.push(image10);
    }
    setFotosOffer(arrayUrls);
  }, [varEffec]);

  //Renderizacion del componente
  return (
    <div className="main-content-det">
      <form
        style={{
          backgroundColor: "#ffffff",
          marginTop: "5px",
          width: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          <Row>
            <Col span={24}>
              <Title
                level={3}
                style={{
                  padding: 2,
                  margin: 2,
                  marginBottom: 15,
                  fontWeight: 600,
                  color: "#6A3E98",
                  textAlign: "center",
                }}
              >
                {detalleOffer.mtv_nombre === "VENDER"
                  ? detalleOffer.tin_nombre +
                    " PARA LA VENTA " +
                    detalleOffer.zna_nombre +
                    " " +
                    detalleOffer.ofr_address
                  : detalleOffer.tin_nombre +
                    " PARA " +
                    detalleOffer.mtv_nombre +
                    " " +
                    detalleOffer.zna_nombre +
                    " " +
                    detalleOffer.ofr_address}
              </Title>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <Card>
                <Card.Grid style={gridStyle}>
                  <span>
                    <Icon path={mdiFloorPlan} size={"60"} color="#6A3E98" />
                    <Title
                      level={4}
                      style={{
                        color: "#6A3E98",
                      }}
                    >
                      {detalleOffer.ofr_area + "m"}
                      <sup>2</sup>
                    </Title>
                  </span>
                </Card.Grid>
                <Card.Grid style={gridStyle}>
                  <span>
                    <Icon path={mdiMapOutline} size={"60"} color="#6A3E98" />
                    <Title
                      level={4}
                      style={{
                        color: "#6A3E98",
                      }}
                    >
                      {detalleOffer.ofr_lote + "m"}
                      <sup>2</sup>
                    </Title>
                  </span>
                </Card.Grid>
                <Card.Grid style={gridStyle}>
                  <span>
                    <Icon path={mdiBedOutline} size={"60"} color="#6A3E98" />
                    <Title
                      level={4}
                      style={{
                        color: "#6A3E98",
                      }}
                    >
                      {detalleOffer.ofr_alcobas}
                    </Title>
                  </span>
                </Card.Grid>
                <Card.Grid style={gridStyle}>
                  <span>
                    <Icon path={mdiToilet} size={"60"} color="#6A3E98" />
                    <Title
                      level={4}
                      style={{
                        color: "#6A3E98",
                      }}
                    >
                      {detalleOffer.ofr_banos}
                    </Title>
                  </span>
                </Card.Grid>
                <Card.Grid style={gridStyle}>
                  <span>
                    <Icon path={mdiCar} size={"50"} color="#6A3E98" />
                    <Title
                      level={4}
                      style={{
                        color: "#6A3E98",
                      }}
                    >
                      {detalleOffer.ofr_parking}
                    </Title>
                  </span>
                </Card.Grid>
              </Card>
            </Col>
            <Col
              span={20}
              style={{ alignContent: "center", paddingLeft: "5px" }}
            >
              <Carousel autoplay={true} effect="fade">
                {fotosOffer.map((foto) => (
                  <FotoOffer
                    foto={foto}
                    detalleOffer={detalleOffer}
                    currentState={currentState}
                  />
                ))}
              </Carousel>
            </Col>
          </Row>
          <Divider
            style={{ marginTop: 3, paddingTop: 3, padding: 3, marginBottom: 3 }}
          />
        </div>
      </form>
    </div>
  );
}

function FotoOffer(props) {
  const { foto, detalleOffer, currentState } = props;
  const backdropPath = foto;

  return (
    <div
      className="slider-movies__movie"
      style={{ backgroundImage: `url('${backdropPath}')` }}
    >
      <div className="slider-movies__movie-info">
        <div>
          <h2>
            {"Precio: " +
              detalleOffer.ofr_precio +
              " " +
              currentState.sta_precio}
          </h2>
          <p>{detalleOffer.ofr_nota}</p>
        </div>
      </div>
    </div>
  );
}
